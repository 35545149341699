import useSWRMutation from "swr/mutation";
import { AppliedModifierTypes, PlayerInterface } from "../../types";
import { AxiosError } from "../../types/request";
import { useFetcher } from "./useFetcher";
import { useGroupPlayers } from "./useGroupPlayers";

type PlayerUpdateScoreModifierArguments = {
  target_player_id: PlayerInterface["id"];
  modifier: AppliedModifierTypes;
};

export function usePlayerApplyTargetedModifier() {
  const { putFetcher } = useFetcher();
  const { mutate: refetchGroupPlayers } = useGroupPlayers();

  const { trigger, isMutating } = useSWRMutation<
    PlayerApplyModifierResponse,
    PlayerApplyModifierErrorResponse,
    string,
    PlayerUpdateScoreModifierArguments
  >(
    "/players/apply_targeted_modifier",
    (url, { arg }) => putFetcher(url, arg),
    {
      onSuccess: () => {
        refetchGroupPlayers();
      },
      revalidate: false,
      populateCache: false,
    }
  );

  return {
    isLoading: isMutating,
    applyTargetedPlayerModifier: trigger,
  };
}

type PlayerApplyModifierResponse = undefined;

type PlayerNotFoundError = {
  type: "not_found";
  message: "Player could not be found";
};

type PlayerApplyModifierErrors = PlayerNotFoundError;

interface PlayerApplyModifierErrorResponse
  extends AxiosError<PlayerApplyModifierErrors> { }
