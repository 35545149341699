import { useContext } from "react";
import { useHistory } from "react-router";
import useSWRMutation from "swr/mutation";
import { AuthContext } from "../../contexts/AuthContext";
import { AxiosError } from "../../types/request";
import { appRoutes } from "../constants";
import { useFetcher } from "./useFetcher";

export function useLogin() {
  const history = useHistory();
  const { updateAuth } = useContext(AuthContext);
  const { postFetcher, getFirstError } = useFetcher();

  const { trigger, isMutating, error } = useSWRMutation<
    LoginResponse,
    LoginError
  >("/login", (url: string, { arg }: any) => postFetcher(url, arg), {
    onSuccess: async (data) => {
      await updateAuth(data.token);
      history.push(appRoutes.dashboard);
    },
    revalidate: false, // auto revalidate after mutation
    populateCache: false, // write back the response to the cache after mutation
  });

  const errorMessage = getFirstError(error);

  return {
    isLoading: isMutating,
    login: trigger,
    errorMessage,
  };
}

type LoginResponse = {
  token: string;
};

type InvalidLoginDetails = {
  type: "unauthorized_invalid_login";
  message: string;
};

type LoginErrors = InvalidLoginDetails;

interface LoginError extends AxiosError<LoginErrors> {}
