import { IonCard, IonList } from "@ionic/react";
import { Reorder } from "framer-motion";
import { useGroupPlayers } from "../../helpers/requests/useGroupPlayers";
import ScoreboardPlayerListItem from "./scoreboard/ScoreboardPlayerListItem";

const Scoreboard = () => {
  const { players } = useGroupPlayers();

  return (
    <IonCard style={{ margin: "0", overflow: 'scroll' }}>
      <Reorder.Group
        axis="y"
        values={players}
        onReorder={() => { }}
        style={{ padding: 0 }} // remove random padding for `ul`
      >
        <IonList>
          {players?.map((player, index) => (
            <Reorder.Item key={`scoreboard-player-${player.id}`} value={player}>
              <ScoreboardPlayerListItem position={index + 1} player={player} />
            </Reorder.Item>
          ))}
        </IonList>
      </Reorder.Group>
    </IonCard>
  );
};

export default Scoreboard;
