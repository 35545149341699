import { useIonToast } from "@ionic/react";
import useSWRMutation from "swr/mutation";
import { useFetcher } from "./useFetcher";
import { useGroupPlayers } from "./useGroupPlayers";

export function useGroupAddBot() {
  const { postFetcher, getFirstError } = useFetcher();
  const { mutate: mutateGroupPlayers } = useGroupPlayers();

  const [present] = useIonToast();

  const { trigger, isMutating } = useSWRMutation(
    "/groups/add_bot",
    (url: string) => postFetcher(url),
    {
      onError: (err) => {
        present({
          position: "middle",
          color: "danger",
          duration: 2000,
          message: getFirstError(err) || "Could not add bot",
        });
      },
      onSuccess: () => {
        mutateGroupPlayers();
      },
      revalidate: false, // auto revalidate after mutation
      populateCache: false, // write back the response to the cache after mutation
    }
  );

  return {
    isLoading: isMutating,
    addBot: trigger,
  };
}
