import {
  IonCard, IonChip,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonNote, IonText
} from "@ionic/react";
import AppliedModifierChip from "../../components/AppliedModifierChip";
import { appliedModifiers, formatNumber, locationModifiers, locations } from "../../helpers/formatters";
import { useNextGame } from "../../helpers/requests/useNextGame";
import LocationProgressBar from "./LocationProgressBar";
import NextGameCountdown from "./NextGameCountdown";

const NextGameCard = () => {
  const { data: game } = useNextGame();

  if (!game) return null;

  const location = game.location;
  const locationBuilder = location && locations[location.location_type];
  const locationMessage =
    locationBuilder && locationBuilder?.buildMessage({ ...location });

  const hasModifiers = location.location_modifiers.length > 0

  return (
    <IonCard style={{ margin: 0 }}>
      <IonItem lines="none" >
        <IonLabel>
          <NextGameCountdown
            targetDate={game.game_time}
            fromDate={game.last_game_time}
          >
            <div style={{
              display: "flex",
              alignItems: "center",
            }}>
              <IonText >
                <h1 style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px"
                }}>
                  {locationBuilder?.icon && (
                    <IonIcon
                      icon={locationBuilder.icon}
                      color={locationBuilder.color}
                    />
                  )}{" "}
                  {locationMessage}{" "}
                  <IonNote>(#{formatNumber(game.game_number)})</IonNote>
                </h1>
              </IonText>

              {hasModifiers &&
                <div
                  style={{
                    gap: "5px",
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "10px",

                  }}
                >
                  {location.location_modifiers.map(
                    (locationModifier, indexFirst) => locationModifier.modifiers.map(
                      (m, indexSecond) => <AppliedModifierChip key={`amc-${indexFirst}-${indexSecond}`} appliedModifier={{ modifier: m }} />
                    )
                  )
                  }
                </div>
              }
            </div>
          </NextGameCountdown>
          {location.target_score > 0 && (
            <LocationProgressBar location={location} />
          )}
        </IonLabel>
      </IonItem>
      <IonList>
        {location.location_modifiers.map((locationModifier, itemIndex) => {
          const builtLocationModifier = locationModifiers[locationModifier.name]

          return (
            <IonItem lines="none" key={`location-modifier-${itemIndex}`}>
              <IonLabel>
                {builtLocationModifier.name}
                <p>{builtLocationModifier.buildMessage({ location_type: location.location_type, name: location.name })}</p>
              </IonLabel>
              <div slot="start">
                <AppliedModifierChip
                  anyModifier={builtLocationModifier}
                />
              </div>
              {locationModifier.modifiers.map((modifierName, chipIndex) => {
                const builtModifier = appliedModifiers[modifierName]

                return (
                  <IonChip key={`location-modifier-${itemIndex}-${chipIndex}`}>
                    <IonIcon icon={builtModifier.icon} color={builtModifier.color}></IonIcon>
                    <IonLabel>{builtModifier.name}</IonLabel>
                  </IonChip>
                )
              })}
            </IonItem>
          )
        })}
      </IonList>
    </IonCard>
  );
};

export default NextGameCard;
