import { IonList, IonProgressBar } from "@ionic/react";
import GroupItem from "../../components/GroupItem";
import Notice from "../../components/Notice";
import { useGroupList } from "../../helpers/requests/useGroupList";
import GroupSearch from "./GroupSearch";

const GroupList = () => {
  const { groups, isLoading, hasGroups } = useGroupList();

  return (
    <div>
      <div className="ion-margin-bottom">
        <GroupSearch />
      </div>
      {isLoading && (
        <div className="ion-padding">
          <IonProgressBar type="indeterminate" />
        </div>
      )}
      {hasGroups && (
        <IonList>
          {groups?.map((group) => (
            <GroupItem key={`group-${group.id}`} group={group} />
          ))}
        </IonList>
      )}

      {!isLoading && !hasGroups && (
        <Notice type="primary">
          No public groups available. Create your own!
        </Notice>
      )}
    </div>
  );
};

export default GroupList;
