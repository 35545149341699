import axios, { AxiosRequestConfig } from "axios";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { AxiosError } from "../../types/request";
import { API_URL } from "../constants";

export function useFetcher() {
  const { authHeader } = useContext(AuthContext);
  const fetcher = axios.create({ baseURL: API_URL, headers: authHeader });

  const getFetcher = (url: string, config?: AxiosRequestConfig<any>) =>
    fetcher.get(url, config).then((res) => res.data);
  const postFetcher = <T>(url: string, data?: T) =>
    fetcher.post(url, data).then((res) => res.data);
  const putFetcher = <T>(url: string, data?: T) =>
    fetcher.put(url, data).then((res) => res.data);

  function getFirstError(
    error: AxiosError<any> | undefined
  ): string | undefined {
    return error?.response?.data?.[0]?.message;
  }

  return {
    getFetcher,
    postFetcher,
    putFetcher,
    getFirstError,
  };
}
