import { IonCard, IonCardContent, IonText } from "@ionic/react";
import Notice from "../../components/Notice";
import { usePlayerSelf } from "../../helpers/requests/usePlayerSelf";
import { useShopList } from "../../helpers/requests/useShopList";
import ShopCardItem from "./ShopCardItem";

const ShopCard = () => {
  const { shopItems } = useShopList();
  const { player } = usePlayerSelf();

  const affordableShopitems = shopItems?.filter(
    (shopItem) => shopItem.price <= (player?.score_total || 0)
  );

  return (
    <IonCard style={{ margin: "0", overflow: "visible" }}>
      <IonCardContent
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {affordableShopitems.length > 0 ? (
          affordableShopitems.map((shopItem) => (
            <ShopCardItem
              key={`shop-item-${shopItem.id}`}
              shopItem={shopItem}
            />
          ))
        ) : (
          <div style={{ width: "100%" }}>
            <Notice type={"primary"}>
              <IonText>Cant afford anything at the shopp :(</IonText>
            </Notice>
          </div>
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default ShopCard;
