import { IonGrid, IonRow, IonCol } from "@ionic/react";
import Notice from "./Notice";

type Props = {};

const NotInAGroupNotice = (props: Props) => {
  return (
    <IonGrid>
      <IonRow className="ion-justify-content-center">
        <IonCol
          sizeXs="12"
          sizeSm="9"
          sizeMd="6"
          className="ion-align-self-center"
        >
          <div className="ion-padding-top">
            <Notice type="error">You do not have a group. :(</Notice>
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default NotInAGroupNotice;
