import useSWR from "swr";
import { ShopItemInterface } from "../../types";
import { AxiosError, RequestError } from "../../types/request";
import { useFetcher } from "./useFetcher";
import { usePlayerSelf } from "./usePlayerSelf";

export function useShopList() {
  const { getFetcher } = useFetcher();
  const { player } = usePlayerSelf();

  const { error, data, mutate } = useSWR<Response, ErrorResponse>(
    `/shops`,
    getFetcher
  );

  return {
    isLoading: !error && !data,
    isError: error,
    shopItems:
      (player &&
        data?.filter((shopItem) => player.score_total > shopItem.price)) ||
      [],
    mutate,
  };
}

type Response = Pick<ShopItemInterface, "id" | "name" | "price">[];

type Errors = RequestError[0];

interface ErrorResponse extends AxiosError<Errors> {}
