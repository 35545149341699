import { IonProgressBar } from '@ionic/react'
import React from 'react'
import { LocationInterface } from '../../types'

type Props = {
    location: Pick<LocationInterface, 'current_score' | 'target_score'>
}

const LocationProgressBar = ({location}: Props) => {
    const locationPercentComplete = Math.round((location.current_score / location.target_score) * 100) / 100

  return (
    <div style={{ marginTop: "10px", display: 'flex', flexDirection: "row", alignItems: "center" }}>
    <IonProgressBar value={locationPercentComplete} color="success" style={{ flexGrow: 1 }} />
    <div style={{display: 'flex', flexDirection: "row", whiteSpace: "nowrap", paddingLeft: "10px"}}>{location.current_score} / {location.target_score}</div>
  </div>
  )
}

export default LocationProgressBar