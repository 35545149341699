import { Doughnut } from "react-chartjs-2";
import { GameInterface, GameThrowInterface, PlayerInterface } from "../types";

type GameThrow = Pick<GameThrowInterface, 'score'> & {
  player: Pick<PlayerInterface, 'is_self'>
}

type Props = {
  gameGroups: {
    game: Pick<GameInterface, 'game_number'>
    game_throws: GameThrow[]
  }[];
};

const colors = [
  "#9a9996",
  "#9a9996",
  "#9a9996",
  "#a59263",
  "#a59263",
  "#ffff00",
  "#ffff00",
  "#ffa500",
  "#ffa500",
  "#cc2200",
];

const LineChartPlayerRollDistribution = ({ gameGroups }: Props) => {
  let playersPoints: Record<number, number> = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
  };

  gameGroups?.forEach((gameGroup) => {
    const gameThrow = gameGroup.game_throws.find(
      (gameThrow) => gameThrow.player.is_self
    );
    if (gameThrow) playersPoints[gameThrow.score] = playersPoints[gameThrow.score] + 1;
  });

  return (
    <Doughnut
      options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "right" as const,
            reverse: true,
            labels: {
              usePointStyle: true,
              padding: 13,
              textAlign: "center",
            },
          },
          title: {
            display: true,
            text: "Roll Distribution",
          },
        },
      }}
      data={{
        labels: Object.keys(playersPoints),
        datasets: [
          {
            backgroundColor: colors,
            borderColor: colors,
            label: "roll",
            data: Object.values(playersPoints),
          },
        ],
      }}
    />
  );
};

export default LineChartPlayerRollDistribution;
