import React, { useContext, useEffect } from "react";
import useChannel from "../helpers/hooks/useActionCableChannel";
import { useDashboard } from "../helpers/requests/useDashboard";
import { useNextGame } from "../helpers/requests/useNextGame";
import { usePlayerSelf } from "../helpers/requests/usePlayerSelf";
import { ActionCableContext } from "./ActionCableContext";
import { GroupEvent, GroupEventsContext } from "./GroupEventsContext";

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

type IContextProps = {
  connectChannels: () => void;
  disconnectChannels: () => void;
};

const ActionCableChannelsContext = React.createContext({} as IContextProps);

const ActionCableChannelsProvider = ({ children }: Props) => {
  const { mutate: fetchNextGame } = useNextGame();
  const { mutate: fetchDashboard } = useDashboard();
  const { addGroupEvent, resetGroupEvents } = useContext(GroupEventsContext);
  const { actionCable } = useContext(ActionCableContext);
  const { inGroup } = usePlayerSelf();

  const {
    subscribe: subscribeGroupEvents,
    unsubscribe: unsubscribeGroupEvents,
  } = useChannel<GroupEvent>(actionCable, "GroupEventsChannel");
  const {
    subscribe: subscribeGroupNextGame,
    unsubscribe: unsubscribeGroupNextGame,
  } = useChannel<NextGameEvent>(actionCable, "GroupNextGameChannel");

  useEffect(() => {
    if (actionCable && inGroup) connectChannels();

    return () => {
      if (actionCable) disconnectChannels();
    };
  }, [actionCable, inGroup]);

  function connectChannels() {
    resetGroupEvents();
    disconnectChannels();

    setTimeout(() => {
      subscribeGroupEvents({
        received(data) {
          addGroupEvent(data);
        },
      });
      subscribeGroupNextGame({
        received() {
          fetchNextGame();
          fetchDashboard();
        },
      });
    }, 100);
  }

  function disconnectChannels() {
    unsubscribeGroupNextGame();
    unsubscribeGroupEvents();
  }

  return (
    <ActionCableChannelsContext.Provider
      value={{
        disconnectChannels,
        connectChannels,
      }}
    >
      {children}
    </ActionCableChannelsContext.Provider>
  );
};

export { ActionCableChannelsContext, ActionCableChannelsProvider };

type NextGameEvent = {
  type: "next_game_ready";
};
