import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { addOutline, refreshOutline } from "ionicons/icons";
import { useGroupList } from "../helpers/requests/useGroupList";
import GroupCreateModal from "./dashboard/GroupCreateModal";
import GroupList from "./dashboard/GroupList";

const GroupsView = () => {
  const { mutate: refreshGroups } = useGroupList();

  const createGroupModalString = "group-create-modal";

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Groups</IonTitle>
          <IonButtons slot="end">
            <IonButton fill="clear" id={createGroupModalString}>
              <IonIcon size="large" icon={addOutline}></IonIcon>
            </IonButton>
            <IonButton fill="clear" onClick={() => refreshGroups()}>
              <IonIcon icon={refreshOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <GroupList />
        <GroupCreateModal modalOpenString={createGroupModalString} />
      </IonContent>
    </IonPage>
  );
};

export default GroupsView;
