import _ from "lodash";
import { useContext } from "react";
import { Line } from "react-chartjs-2";
import { AppSettingsContext } from "../contexts/AppSettingsContext";
import { GameInterface, GameThrowInterface, PlayerInterface } from "../types";

type GameThrow = Pick<GameThrowInterface, 'score_awarded'> & {
  player: Pick<PlayerInterface, 'bot' | 'unique_id' | 'chart_color'>
}

type GameGrouped = {
  game: Pick<GameInterface, 'game_number'>
  game_throws: GameThrow[]
}

type Props = {
  gameGroups: GameGrouped[];
};

const colors = [
  "#999",
  // 'rgba(255, 206, 86, 1)',
  // 'rgba(75, 192, 192, 1)',
  // 'rgba(153, 102, 255, 1)',
  // 'rgba(255, 159, 64, 1)',
];

const LineChartPlayerScoreHistory = ({ gameGroups }: Props) => {
  const { appSettings } = useContext(AppSettingsContext);

  let playersPoints: Record<string, number[]> = {};
  let playerColors: Record<string, string> = {};

  gameGroups?.forEach((gameGroup) => {
    gameGroup.game_throws.forEach((gt) => {
      if (gt.player.bot && !appSettings.showBotsInCharts) {
        return;
      }
      if (playersPoints[gt.player.unique_id] === undefined) {
        playersPoints[gt.player.unique_id] = [];
      }
      const previousScores = playersPoints[gt.player.unique_id];
      const previousScore = _.last(previousScores) || 0;

      playersPoints[gt.player.unique_id].push(previousScore + gt.score_awarded);
      playerColors[gt.player.unique_id] = gt.player.chart_color;
    });
  });

  const labels = gameGroups.map((gg) => gg.game.game_number.toString());
  const names = Object.keys(playersPoints);
  const values = Object.values(playersPoints).map((points, index) => {
    const name = names[index];
    const color = playerColors[name] || _.sample(colors);

    return {
      label: names[index],
      data: points,
      borderColor: color,
      backgroundColor: color,
      borderWidth: 1,
    };
  });

  return (
    <Line
      options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "top" as const,
            labels: {
              usePointStyle: true,
            },
          },
          title: {
            display: true,
            text: "Score Accumulated",
          },
        },
      }}
      data={{
        labels,
        datasets: values,
      }}
    />
  );
};

export default LineChartPlayerScoreHistory;
