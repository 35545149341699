import React, { useEffect, useState } from "react";
import { appRoutes } from "../helpers/constants";
import {
  getAuthTokenStorage,
  setAuthTokenStorage,
} from "../helpers/storage/auth-token";

class InvalidAuthError extends Error {}

interface AuthInterface {
  auth: string | null;
}

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

type IContextProps = {
  auth: AuthInterface["auth"];
  updateAuth: (newAuth: AuthInterface["auth"]) => Promise<void>;
  removeAuth: () => Promise<void>;
  authHeader: { Authorization: string };
  initializing: boolean;
  isAuthenticated: boolean;
};

const AuthContext = React.createContext({} as IContextProps);

const AuthProvider = (props: Props) => {
  const [initializing, setInitializing] = useState(true);
  const [auth, setAuth] = useState<AuthInterface["auth"]>(null);

  useEffect(() => {
    async function init() {
      const savedAuth = await getAuthTokenStorage();
      setAuth(savedAuth);
      setInitializing(false);
    }
    init();
  }, []);

  async function updateAuth(newAuth: AuthInterface["auth"]) {
    if (!newAuth) {
      throw new InvalidAuthError("No auth token found");
    }
    setAuth(newAuth);
    setAuthTokenStorage(newAuth);
  }

  async function removeAuth() {
    setAuth(null);
    await setAuthTokenStorage(null);
    window.location.assign(appRoutes.login);
  }

  const authHeader = { Authorization: auth } as IContextProps["authHeader"];
  const isAuthenticated = !!auth;

  return (
    <AuthContext.Provider
      value={{
        auth,
        isAuthenticated,
        updateAuth,
        removeAuth,
        authHeader,
        initializing,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
