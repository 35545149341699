import {
  IonBadge,
  IonChip,
  IonIcon,
  IonItem,
  IonLabel,
  IonNote,
} from "@ionic/react";
import { heartCircle } from "ionicons/icons";
import _ from "lodash";
import ModifierChip from "../../../components/ModifierChip";
import PlayerInteractionModal from "../../../components/PlayerInteractionModal";
import { appliedModifiers, formatNumber } from "../../../helpers/formatters";
import { PlayerInterface, TargetedModifier } from "../../../types";

type Props = {
  player: Pick<
    PlayerInterface,
    | "id"
    | "name"
    | "score_modifier"
    | "score_total"
    | "unique_id"
    | "bot"
    | "is_friend"
    | "is_self"
    | "catchphrase"
  > & {
    targeted_modifiers_target: Pick<
      TargetedModifier,
      "id" | "modifier" | "source_player_id" | "target_player_id"
    >;
  };
  position: number;
};

const ScoreboardPlayerListItem = ({ player, position }: Props) => {
  const modalOpenString = `player-interaction-${player.id}`;

  const targetedModifiersGroupedByModifier = _.groupBy(
    player.targeted_modifiers_target,
    "modifier"
  );

  const poisionedCount =
    targetedModifiersGroupedByModifier?.["poisoned"]?.length || 0;

  const cursedCount =
    targetedModifiersGroupedByModifier?.["cursed"]?.length || 0;

  function textEffect(): string {
    switch (position) {
      case 1:
        return "text-effect--disco-party";
      default:
        return "";
    }
  }

  return (
    <IonItem button detail={false} id={modalOpenString}>
      <div slot="start" style={{ fontWeight: "bold" }}>
        {position}
      </div>

      <IonLabel style={{ display: "flex", alignItems: "center" }}>
        <div className={textEffect()}>{player.unique_id}</div>
        {player.bot && (
          <IonBadge color="medium" style={{ marginLeft: "5px" }}>
            bot
          </IonBadge>
        )}
        {player.is_friend && (
          <IonIcon
            color="danger"
            style={{ marginLeft: "5px" }}
            icon={heartCircle}
          />
        )}
        {poisionedCount > 0 &&
          _.fill(Array(poisionedCount), true).map((_value, index) => (
            <IonIcon
              key={`${player.id}-poisoned-${index}`}
              color={appliedModifiers.poisoned.color}
              icon={appliedModifiers.poisoned.icon}
              style={{ marginLeft: "5px" }}
            />
          ))}
        {cursedCount > 0 &&
          _.fill(Array(cursedCount), true).map((_value, index) => (
            <IonIcon
              key={`${player.id}-cursed-${index}`}
              color={appliedModifiers.cursed.color}
              icon={appliedModifiers.cursed.icon}
              style={{ marginLeft: "5px" }}
            />
          ))}
      </IonLabel>

      <IonNote slot="end">
        <IonChip className="margin-10-end">
          {formatNumber(player.score_total)}
        </IonChip>
        <ModifierChip scoreModifier={player.score_modifier} />
      </IonNote>
      <PlayerInteractionModal
        player={player}
        modalOpenString={modalOpenString}
      />
    </IonItem>
  );
};

export default ScoreboardPlayerListItem;
