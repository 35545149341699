import GamesCharts from "../../components/GamesCharts";

type Props = {};

const GamesSegment = (props: Props) => {
  return (
    <div>
      <GamesCharts />
      {/* <GamesList /> */}
    </div>
  );
};

export default GamesSegment;
