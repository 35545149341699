import useSWR from "swr";
import { GroupEvent } from "../../contexts/GroupEventsContext";
import { AxiosError, RequestError } from "../../types/request";
import { useFetcher } from "./useFetcher";
import { usePlayerSelf } from "./usePlayerSelf";

export function useGroupEventLogs() {
  const { getFetcher } = useFetcher();
  const { inGroup } = usePlayerSelf();

  const { error, data, mutate } = useSWR<Response, Errors>(
    inGroup ? `/groups/event_logs` : undefined,
    getFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnMount: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    }
  );

  return {
    isLoading: !error && !data,
    isError: error,
    groupEvents: data,
    mutate,
  };
}

type Response = GroupEvent[];

interface Errors extends AxiosError<RequestError[]> {}
