import { useEffect, useState } from "react";

const useCountdown = (targetDate: string, fromDate: string) => {
  const countDownDate = new Date(targetDate).getTime();
  // const countFromDate = new Date(fromDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  const getProgress = () => {
    const start = new Date(targetDate);
    const end = new Date(fromDate);
    const total = end.getTime() - start.getTime();
    const elaps = Date.now() - start.getTime();
    return Math.round((elaps / total) * 100) / 100;
  };

  return { count: getReturnValues(countDown), percentage: 1 - getProgress() };
};

const getReturnValues = (countDown: number) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
};

export { useCountdown };
