import {
  IonAccordion,
  IonAccordionGroup,
  IonItem,
  IonLabel,
  IonList,
  IonText,
} from "@ionic/react";
import { motion } from "framer-motion";
import { chatboxEllipsesOutline } from "ionicons/icons";
import { useContext } from "react";
import AppliedModifierChip from "../../components/AppliedModifierChip";
import { GroupEventsContext } from "../../contexts/GroupEventsContext";
import {
  formatGroupEventmessage,
  formatTimeAt,
} from "../../helpers/formatters";
import {
  PlayerSelfResponse,
  usePlayerSelf,
} from "../../helpers/requests/usePlayerSelf";
import { listItemAnimation } from "../../theme/listItemAnimation";

const GroupEvents = () => {
  const { groupEvents } = useContext(GroupEventsContext);
  const { player } = usePlayerSelf();

  return (
    <IonAccordionGroup expand="compact">
      <IonAccordion
        className="border-radius"
        toggleIcon={chatboxEllipsesOutline}
        toggleIconSlot="start"
      >
        <IonItem slot="header">
          <IonLabel>
            <IonText color="medium">
              <h2>Events</h2>
            </IonText>
          </IonLabel>
        </IonItem>
        <div
          className="accordian-content-card-bg-color accordian-content-card-bg-color scrollable-accordian-content scrollable-accordian-content--small"
          slot="content"
        >
          <motion.div
            initial="hidden"
            animate="visible"
            variants={listItemAnimation.listVariants}
          >
            <IonList>
              {groupEvents.map((groupEvent, index) => {
                const formattedGroupMessage = formatGroupEventmessage(
                  groupEvent,
                  (player as PlayerSelfResponse).unique_id
                );

                return (
                  <motion.div
                    variants={listItemAnimation.itemVariants}
                    key={`group-event-${index}`}
                  >
                    <IonItem>
                      <>
                        {groupEvent.modifier && (
                          <div slot="start">
                            <AppliedModifierChip
                              appliedModifier={{
                                modifier: groupEvent.modifier,
                              }}
                            />
                          </div>
                        )}

                        <IonLabel>{formattedGroupMessage}</IonLabel>
                      </>
                      <IonLabel slot="end">
                        <IonText color="medium">
                          <p>{formatTimeAt(groupEvent.time_stamp)}</p>
                        </IonText>
                      </IonLabel>
                    </IonItem>
                  </motion.div>
                );
              })}
            </IonList>
          </motion.div>
        </div>
      </IonAccordion>
    </IonAccordionGroup>
  );
};

export default GroupEvents;
