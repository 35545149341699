import {
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonList,
  IonNote,
  useIonViewDidLeave,
} from "@ionic/react";
import { closeCircleOutline, searchOutline } from "ionicons/icons";
import { useState } from "react";
import GroupItem from "../../components/GroupItem";
import { useGroupShow } from "../../helpers/requests/useGroupShow";

const GroupSearch = () => {
  const [search, setSearch] = useState("");
  const [hasError, setHasError] = useState<string | null>(null);
  const { group, setGroupId } = useGroupShow({
    handleOnSuccess: () => {
      setSearch("");
      setHasError(null);
    },
    handleOnError: () => {
      setHasError("Group not found");
    },
  });

  function handleSearchGroup() {
    setGroupId(search);
  }

  function handleClearSearch() {
    setGroupId(null);
    setSearch("");
  }

  function handleUpdateSearch(newValue: string) {
    setSearch(newValue);
    if (hasError) setHasError(null);
  }

  useIonViewDidLeave(() => {
    handleClearSearch();
  });

  return (
    <IonList>
      <IonItem>
        <IonIcon icon={searchOutline} slot="start" />
        <IonInput
          autofocus
          title="Group ID"
          type="text"
          value={search}
          minlength={1}
          inputmode="numeric"
          onIonChange={(e) => handleUpdateSearch(e.detail.value?.toString() || '')}
          onKeyUp={(e) => e.key === "Enter" && handleSearchGroup()}
        />
        {search && hasError && (
          <IonNote slot="error" className="ion-padding-bottom">
            Group not found
          </IonNote>
        )}
        <div slot="end">
          {search && (
            <IonButton fill="clear" onClick={handleClearSearch}>
              <IonIcon icon={closeCircleOutline} />
            </IonButton>
          )}
          <IonButton fill="clear" color="medium" onClick={handleSearchGroup}>
            Search
          </IonButton>
        </div>
      </IonItem>
      {group && <GroupItem group={group} />}
    </IonList>
  );
};

export default GroupSearch;
