import { IonCard, IonIcon, IonText } from "@ionic/react";
import { modifiersArray } from "../../helpers/formatters";
import { usePlayerSelf } from "../../helpers/requests/usePlayerSelf";
import { usePlayerUpdateModifier } from "../../helpers/requests/usePlayerUpdateModifier";

const UserModifierCard = () => {
  const { player } = usePlayerSelf();
  const { updatePlayerModifier } = usePlayerUpdateModifier();

  return (
    <IonCard style={{ margin: 0, padding: "5px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {modifiersArray.map((modifier) => (
          <div
            key={`modifier-${modifier.id}`}
            className={`card-modifier ${modifier.id === player?.score_modifier
              ? "card-modifier--selected"
              : ""
              }`}
            onClick={() =>
              updatePlayerModifier({ score_modifier: modifier.id })
            }
          >
            <IonIcon
              icon={modifier.icon}
              style={{ fontSize: "20px" }}
            />
            <IonText>
              <p style={{ fontSize: "13px", margin: 0, paddingTop: "5px" }}>{modifier.name}</p>
            </IonText>
          </div>
        ))}
      </div>
    </IonCard>
  );
};

export default UserModifierCard;
