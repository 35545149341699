import useSWR from "swr";
import { GameInterface, GameThrowInterface, GroupInterface, PlayerInterface } from "../../types";
import { AxiosError } from "../../types/request";
import { useFetcher } from "./useFetcher";
import { useGroupPlayers } from "./useGroupPlayers";

export function useDashboard() {
  const { getFetcher } = useFetcher();
  const { mutate: mutateGroupPlayers } = useGroupPlayers();

  const { error, data, mutate, isLoading } = useSWR<
    DashboardResponse,
    DashboardError
  >(`/players/dashboard`, getFetcher, {
    onSuccess() {
      mutateGroupPlayers();
    },
  });

  return {
    isLoading,
    isError: error,
    data,
    mutate,
  };
}

type GameThrow = Pick<GameThrowInterface, 'id' | 'applied_modifiers' | 'round' | 'score' | 'score_awarded' | 'score_modifier' | 'score_supplementary' | 'winner'> & {
  player: Pick<PlayerInterface, 'id' | 'score_total' | 'bot' | 'name' | 'unique_id' | 'is_self'>;
}

type DashboardResponse = {
  group: Pick<
    GroupInterface,
    | "id"
    | "name"
    | "players_count"
    | "password_protected"
    | "frequency_in_seconds"
  >;
  game: Pick<GameInterface, 'id' | 'game_number' | 'game_time' | 'location' | 'name' | 'round'>;
  game_throws: GameThrow[];
};

type InvalidLoginDetails = {
  type: "unauthorized_invalid_login";
  message: string;
};

type DashboardErrors = InvalidLoginDetails;

interface DashboardError extends AxiosError<DashboardErrors> {}
