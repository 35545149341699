import {
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonNote,
  useIonViewDidLeave,
} from "@ionic/react";
import { closeCircleOutline, personAddOutline } from "ionicons/icons";
import { useState } from "react";
import { useFriendAdd } from "../../helpers/requests/useFriendAdd";

const FriendAdd = () => {
  const [search, setSearch] = useState("");
  const [hasError, setHasError] = useState<string | null>(null);
  const { addFriend, errorMessage } = useFriendAdd({
    handleOnSuccess: () => {
      setSearch("");
      setHasError(null);
    },
  });

  async function handleAddFriend() {
    addFriend(
      { unique_id: search },
      {
        onError: (e) => setHasError(e.response.data[0].message),
      }
    );
  }

  function handleClearSearch() {
    setSearch("");
    setHasError(null);
  }

  function handleUpdateSearch(newValue: string) {
    setSearch(newValue);
    if (hasError) setHasError(null);
  }

  useIonViewDidLeave(() => {
    handleClearSearch();
  });

  return (
    <IonItem>
      <IonIcon icon={personAddOutline} slot="start" />
      <IonInput
        autofocus
        title="Friend ID"
        type="text"
        required
        value={search}
        minlength={1}
        placeholder="MrFun#123"
        onIonChange={(e) => handleUpdateSearch(e.detail.value?.toString() || '')}
        onKeyUp={(e) => e.key === "Enter" && handleAddFriend()}
      />
      <div slot="end">
        {search && (
          <IonButton fill="clear" onClick={handleClearSearch}>
            <IonIcon icon={closeCircleOutline} />
          </IonButton>
        )}
        <IonButton fill="clear" color="medium" onClick={handleAddFriend}>
          Add
        </IonButton>
      </div>

      {search && hasError && (
        <IonNote slot="error" className="ion-padding-bottom">
          {errorMessage}
        </IonNote>
      )}
    </IonItem>
  );
};

export default FriendAdd;
