import {
  IonButton,
  IonChip,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  useIonToast,
} from "@ionic/react";
import { peopleOutline } from "ionicons/icons";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import { ActionCableChannelsContext } from "../contexts/ActionCableChannelsContext";
import { appRoutes } from "../helpers/constants";
import { useDashboard } from "../helpers/requests/useDashboard";
import { useGroupJoin } from "../helpers/requests/useGroupJoin";
import { useNextGame } from "../helpers/requests/useNextGame";
import { GroupInterface } from "../types";

type Props = {
  group: Pick<
    GroupInterface,
    "id" | "name" | "players_count" | "password_protected"
  >;
};

const GroupItem = ({ group }: Props) => {
  const { connectChannels } = useContext(ActionCableChannelsContext);

  const history = useHistory();
  const [password, setPassword] = useState("");
  const [focus, setFocus] = useState(false);
  const [present] = useIonToast();
  const { joinGroup, error } = useGroupJoin();
  const { mutate: refetchDashboard } = useDashboard();
  const { mutate: refetchNext } = useNextGame();

  async function handleJoinGroup() {
    if (!group.password_protected) {
      try {
        await joinGroup({ id: group.id });
        connectChannels();
        refetchNext();
        await refetchDashboard();
        history.push(appRoutes.dashboard);
      } catch (err) {
        console.log("not password_protected err", err);
      }
    } else {
      try {
        await joinGroup({ id: group.id, password });
        refetchNext();
        await refetchDashboard();
        connectChannels();
        history.push(appRoutes.dashboard);
      } catch (err: any) {
        const message =
          err?.response?.data?.[0]?.message || "Could not join group";
        present({
          position: "top",
          color: "danger",
          duration: 2000,
          message,
        });
      }
    }
  }

  const submitButton = (
    <IonButton
      fill="clear"
      slot="end"
      disabled={group.password_protected ? !password : false}
      onClick={() => handleJoinGroup()}
    >
      Join!
    </IonButton>
  );

  return (
    <IonItem>
      <IonLabel>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            {group.players_count !== undefined && (
              <IonChip>
                <IonIcon icon={peopleOutline} color="success" />
                <IonLabel>{group.players_count}</IonLabel>
              </IonChip>
            )}
            {group.name}{" "}
          </div>
          {!group.password_protected && <>{submitButton}</>}
        </div>
      </IonLabel>

      {group.password_protected && (
        <>
          <IonInput
            title={"Password"}
            className={[focus && error ? "input--error" : "input--focus"].join(
              " "
            )}
            type="text"
            required
            value={password}
            minlength={1}
            placeholder="Password"
            onIonFocus={() => setFocus(true)}
            onIonBlur={() => setFocus(false)}
            onIonChange={(e) => setPassword(e.detail.value?.toString() || '')}
            onKeyUp={(e) => {
              if (!password) return;
              if (e.key === "Enter") {
                handleJoinGroup();
              }
            }}
          />
          {submitButton}
        </>
      )}
    </IonItem>
  );
};

export default GroupItem;
