import { IonInput, IonItem, IonLabel, IonNote } from "@ionic/react";
import { useState } from "react";

type Props = {
  onChange: (value: string) => void;
  value: string;
  handleEnter: () => Promise<void>;
};

const MATCH_REGEX = /^[A-Za-z]+$/;

export function signupValidation(value: string): string | null {
  if (!value.match(MATCH_REGEX)) {
    return "letters_only";
  } else if (value.length <= 3) {
    return "more_than_3";
  } else if (value.length >= 14) {
    return "less_than_15";
  } else {
    return null;
  }
}

const SignupInput = ({ onChange, value, handleEnter }: Props) => {
  const [validationError, setvalidationError] = useState<string | null>(null);
  const [blurred, setBlurred] = useState(false);

  function handleChange(newValue: string) {
    const validation = signupValidation(newValue);
    switch (validation) {
      case "letters_only":
        setvalidationError("Letters only");
        break;
      case "more_than_3":
        setvalidationError("More than 3 characters");
        break;
      case "less_than_15":
        setvalidationError("Less than 15 characters");
        break;
      default:
        setvalidationError(null);
        break;
    }

    onChange(newValue);
  }

  const isValid = value && !validationError;
  const textColor =
    (validationError && "danger") || (isValid && "success") || undefined;

  return (
    <IonItem>
      <IonLabel position="fixed" color={textColor}>
        username
      </IonLabel>
      <IonInput
        autofocus
        type="text"
        required
        pattern="^[A-Za-z]+$"
        value={value}
        maxlength={16}
        minlength={4}
        onIonChange={(e) => handleChange(e.detail.value?.toString() || '')}
        onIonBlur={(e) => {
          console.log("blurred", e);
          setBlurred(true);
        }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            handleEnter?.();
          }
        }}
      />
      {blurred && validationError && (
        <IonNote slot="error" style={{ paddingBottom: "12px" }}>
          {validationError}
        </IonNote>
      )}
    </IonItem>
  );
};

export default SignupInput;
