import React from "react";

type NoticeType = "primary" | "warning" | "success" | "error" | "medium";

export function getColor(color: NoticeType): string | null {
  switch (color) {
    case "primary":
      return "--ion-color-secondary";
    case "success":
      return "--ion-color-success";
    case "warning":
      return "--ion-color-warning";
    case "error":
      return "--ion-color-danger";
    case "medium":
      return "--ion-color-medium";
    default:
      return null;
  }
}

type Props = {
  type: NoticeType;
  fill?: "outlined" | "solid";
  children: React.ReactNode | React.ReactNode[];
  onClick?: () => void;
};

const Notice = ({ type, fill = "outlined", children, onClick }: Props) => {
  const mainColour = `var(${getColor(type)})`;
  return (
    <div
      style={{
        padding: "15px",
        border: `1px solid ${mainColour}`,
        borderRadius: "5px",
        color:
          fill === "solid" ? `var(--ion-color-light)` : `var(--ion-color-dark)`,
        backgroundColor: fill === "solid" ? mainColour : "unset",
        display: "flex",
        flexDirection: "column",
        ...(onClick ? { cursor: "pointer" } : {}),
      }}
    >
      {children}
    </div>
  );
};

export default Notice;
