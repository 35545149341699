import { IonBadge, IonItem, IonLabel, IonNote, IonText } from "@ionic/react";
import _ from "lodash";
import AppliedModifierChip from "../../components/AppliedModifierChip";
import ModifierChip from "../../components/ModifierChip";
import { PlayerGameThrowModalItem } from "../../components/PlayerGameRoundModal";
import { formatNumber, getScoreColor } from "../../helpers/formatters";
import { GameThrowInterface, PlayerInterface } from "../../types";

type Props = {
  gameThrow: Pick<
    GameThrowInterface,
    | "id"
    | "score"
    | "score_supplementary"
    | "winner"
    | "score_awarded"
    | "score_modifier"
    | "applied_modifiers"
  > & {
    player: Pick<
      PlayerInterface,
      "id" | "unique_id" | "is_self" | "unique_id" | "bot"
    >;
  };
  showModal: boolean;
  handleGameThrowItemClick: (item: PlayerGameThrowModalItem) => void
};

const GameGroupCardPlayerItem = ({ gameThrow, handleGameThrowItemClick }: Props) => {
  const { player } = gameThrow
  const scoreColor = getScoreColor(gameThrow.score);

  const hasModifiers = gameThrow.applied_modifiers.length > 0
  const groupedModifiers = _.groupBy(gameThrow.applied_modifiers, applied_modifier => [applied_modifier.modifier, applied_modifier.cause])

  return (
    <IonItem
      id="group-player-item-modal"
      className="ion-item--game-player-row"
      color={player.is_self ? "light" : undefined}
      style={{ borderRadius: "0" }}
      button={!!handleGameThrowItemClick}
      detail={false}
      onClick={() => handleGameThrowItemClick(gameThrow)}
    >
      <div style={{ display: "flex", gap: "3px", width: "67px" }}>
        <IonBadge color={scoreColor}>
          <b>{gameThrow.score}</b>
        </IonBadge>
        <IonBadge color="light">
          <IonNote color="medium" style={{ fontSize: "10px" }}>
            {gameThrow.score_supplementary}
          </IonNote>
        </IonBadge>
      </div>
      <IonLabel>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: "5px",
          }}
        >
          <div className="margin-5-end">
            <ModifierChip scoreModifier={gameThrow.score_modifier} />

          </div>
          <IonText
            color={player.is_self ? "success" : "medium"}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {player.unique_id}

            {player.bot && (
              <IonBadge color="medium" style={{ marginLeft: "5px" }}>
                bot
              </IonBadge>
            )}
          </IonText>
        </div>
      </IonLabel>

      <IonNote slot="end" style={{ display: "flex" }}>
        {hasModifiers &&
          <>
            <div
              style={{
                display: "flex",
                gap: "5px",
                flexDirection: "row",
              }}
            >
              {Object.values(groupedModifiers).map((appliedModifiers, index) => {
                const appliedModifier = appliedModifiers[0]
                const total = appliedModifiers.length

                return (
                  <AppliedModifierChip
                    key={`amc-${index}`}
                    appliedModifier={appliedModifier}
                    count={total}
                  />
                )
              })}
            </div>
            <span className="applied-modifier-divider" />
          </>
        }


        <IonText
          color={gameThrow.score_awarded > 0 ? "success" : "danger"}
          className="margin-10-end margin-10-start"
        >
          {gameThrow.score_awarded > 0 && "+"}
          {formatNumber(gameThrow.score_awarded)}
        </IonText>
      </IonNote>
    </IonItem>
  );
};

export default GameGroupCardPlayerItem;
