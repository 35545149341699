import { IonCard, IonCardContent } from "@ionic/react";
import { useContext } from "react";
import { AppSettingsContext } from "../contexts/AppSettingsContext";
import { useGroupGames } from "../helpers/requests/useGroupGames";
import ChartHOC from "./ChartHOC";
import LineChartPlayerAwardedPoints from "./LineChartPlayerAwardedPoints";
import LineChartPlayerRollDistribution from "./LineChartPlayerRollDistribution";
import LineChartPlayerScoreHistory from "./LineChartPlayerScoreHistory";

type Props = {};

const GamesCharts = (props: Props) => {
  const { gamesOrderedByRound } = useGroupGames();
  const { appSettings } = useContext(AppSettingsContext);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
      }}
    >
      {appSettings.showChartScoreAccumulated && (
        <IonCard className="no-margin-x no-margin-y">
          <IonCardContent>
            <ChartHOC>
              <LineChartPlayerScoreHistory gameGroups={gamesOrderedByRound} />
            </ChartHOC>
          </IonCardContent>
        </IonCard>
      )}

      {appSettings.showChartScoreAwarded && (
        <IonCard className="no-margin-x no-margin-y">
          <IonCardContent>
            <ChartHOC>
              <LineChartPlayerAwardedPoints
                gameGroups={gamesOrderedByRound.reverse()}
              />
            </ChartHOC>
          </IonCardContent>
        </IonCard>
      )}

      {appSettings.showChartRollDistribution && (
        <IonCard className="no-margin-x no-margin-y">
          <IonCardContent>
            <ChartHOC>
              <LineChartPlayerRollDistribution gameGroups={gamesOrderedByRound} />
            </ChartHOC>
          </IonCardContent>
        </IonCard>
      )}
    </div>
  );
};

export default GamesCharts;
