import {
  IonButton,
  IonButtons, IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter
} from "@ionic/react";
import { settingsOutline } from "ionicons/icons";
import { useState } from "react";
import ChartSettingsButton from "../components/ChartSettingsButton";
import GroupSettingsModal from "../components/GroupSettingsModal";
import NotInAGroupNotice from "../components/NotInAGroupNotice";
import PlayerGameRoundModal, {
  PlayerGameThrowModalItem
} from "../components/PlayerGameRoundModal";
import { useDashboard } from "../helpers/requests/useDashboard";
import { usePlayerSelf } from "../helpers/requests/usePlayerSelf";
import GameGroupCard from "./dashboard/GameGroupCard";
import GamesSegment from "./dashboard/GamesSegment";
import GroupEvents from "./dashboard/GroupEvents";
import NextGameCard from "./dashboard/NextGameCard";
import Scoreboard from "./dashboard/Scoreboard";
import ShopCard from "./dashboard/ShopCard";
import UserModifierCard from "./dashboard/UserModifierCard";

const segments = {
  dashboard: "dashboard",
  shop: "shop",
  scoreboard: "scoreboard",
  games: "games",
};

const Dashboard = () => {
  const { data, mutate: fetchDashboard } = useDashboard();
  const { playerNotInGroup } = usePlayerSelf();
  const [gameGroupPlayerItemModal, setGameGroupPlayerItemModal] =
    useState<PlayerGameThrowModalItem | null>(null);

  const [segment, setSegment] = useState<string>(segments.dashboard);

  const { group, game, game_throws: gameThrows } = data || {};

  const currentRoundGameThrows = (gameThrows || []) // .filter(gameThrow => gameThrow.round === ((game?.round || 0)) - 1)

  useIonViewWillEnter(() => {
    fetchDashboard();
  });

  function handleGameThrowItemClick(item: PlayerGameThrowModalItem) {
    setGameGroupPlayerItemModal(item);
  }

  const modalOpenString = `group-settings-modal`;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Dashboard</IonTitle>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonButtons slot="end">
            {segment === segments.games && <ChartSettingsButton />}
            {data && group && (
              <IonButton fill="clear" color="medium" id={modalOpenString}>
                <IonIcon icon={settingsOutline} />
                <GroupSettingsModal
                  modalOpenString={modalOpenString}
                  group={group}
                />
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {playerNotInGroup && <NotInAGroupNotice />}
        {data && group && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{
              padding: "0 15px",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
              marginTop: "15px",
            }}>
              <NextGameCard />
              <UserModifierCard />

              <IonSegment
                value={segment}
                onIonChange={(event) =>
                  setSegment(event.target.value as string)
                }
              >
                <IonSegmentButton value={segments.dashboard}>
                  <IonLabel>Game</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value={segments.scoreboard}>
                  <IonLabel>Leaderboard</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value={segments.games}>
                  <IonLabel>Stats</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value={segments.shop}>
                  <IonLabel>Shop</IonLabel>
                </IonSegmentButton>
              </IonSegment>
              <SegmentVisiblity
                renderType="display"
                segmentId={segments.dashboard}
                currentSegment={segment}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <GroupEvents />

                  {game && currentRoundGameThrows.length > 0 && (
                    <GameGroupCard
                      key={`${game.game_number}-${game.round}`}
                      isDashboard={true}
                      game={game}
                      gameThrows={currentRoundGameThrows}
                      handleGameThrowItemClick={handleGameThrowItemClick}
                    />
                  )}
                </div>
              </SegmentVisiblity>

              <SegmentVisiblity
                renderType="unmount"
                segmentId={segments.scoreboard}
                currentSegment={segment}
              >
                <Scoreboard />
              </SegmentVisiblity>
              <SegmentVisiblity
                segmentId={segments.games}
                currentSegment={segment}
                renderType="display"
              >
                <GamesSegment />
              </SegmentVisiblity>

              <SegmentVisiblity
                renderType="unmount"
                segmentId={segments.shop}
                currentSegment={segment}
              >
                <ShopCard />
              </SegmentVisiblity>
            </div>
          </div>
        )}
        <PlayerGameRoundModal
          gameThrow={gameGroupPlayerItemModal}
          modalOpenString={`group-player-item-modal`}
          onClose={() => setGameGroupPlayerItemModal(null)}
        />
      </IonContent>
    </IonPage>
  );
};

export default Dashboard;

type SegmentVisiblityProps = {
  segmentId: string;
  currentSegment: string;
  children: JSX.Element;
  renderType: "display" | "unmount";
};

const SegmentVisiblity = ({
  segmentId,
  currentSegment,
  children,
  renderType,
}: SegmentVisiblityProps): JSX.Element | null => {
  const isActive = currentSegment === segmentId;

  if (renderType === "unmount") {
    return isActive ? children : null;
  } else if (renderType === "display") {
    return (
      <div
        style={{
          display: isActive ? "block" : "none",
        }}
      >
        {children}
      </div>
    );
  } else {
    return children;
  }
};
