import _ from "lodash";
import useSWR from "swr";
import { GameInterface, GameThrowInterface, PlayerInterface } from "../../types";
import { AxiosError, RequestError } from "../../types/request";
import { useFetcher } from "./useFetcher";

export function useGroupGames() {
  const { getFetcher } = useFetcher();

  const { error, data, mutate } = useSWR<
    Response,
    Errors
  >(`/groups/games`, getFetcher, {
    revalidateOnFocus: false,
  });

  const gamesOrderedByRound = _.orderBy(data, (game) => game.game.game_number)


  return {
    isLoading: !error && !data,
    isError: error,
    games: data,
    gamesOrderedByRound,
    mutate,
  };
}

type GameThrow = Pick<GameThrowInterface, "id" | 'score' | 'score_awarded' | 'score_modifier' | 'score_supplementary' | 'applied_modifiers' | 'winner' | 'round'> & {
  player: Pick<PlayerInterface, 'id' | 'score_total' | 'bot' | 'name' | 'unique_id' | 'is_self' | 'chart_color'>;
}

type Response = {
  game: Pick<GameInterface, "id" | "game_number" | "game_time" | 'location' | 'round' | 'name'>;
  game_throws: GameThrow[];
}[];

interface Errors extends AxiosError<RequestError[]> { }
