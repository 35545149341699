import useSWRMutation from "swr/mutation";
import { AxiosError } from "../../types/request";
import { PlayerSerializer } from "../../types/serializers";
import { useFetcher } from "./useFetcher";
import { useFriendsList } from "./useFriendsList";

type Props = {
  handleOnSuccess?: () => void;
};

export function useFriendAdd({ handleOnSuccess }: Props = {}) {
  const { postFetcher, getFirstError } = useFetcher();
  const { mutateFriendsList } = useFriendsList();

  const { trigger, isMutating, error } = useSWRMutation<
    PlayerSerializer,
    AddFriendErrorResponse
  >("/friends/add", (url: string, { arg }: any) => postFetcher(url, arg), {
    revalidate: false,
    populateCache: false,
    onSuccess: (response) => {
      mutateFriendsList(
        (data) =>
          data && {
            ...response,
            ...data,
          }
      );
      handleOnSuccess?.();
    },
  });

  const errorMessage = getFirstError(error);

  return {
    isLoading: isMutating,
    addFriend: trigger,
    errorMessage,
  };
}

type PlayerNotFound = {
  type: "not_found";
  message: "Player could not be found";
};

type AddFriendErrors = PlayerNotFound;

interface AddFriendErrorResponse extends AxiosError<AddFriendErrors> {}
