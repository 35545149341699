import { useIonToast } from "@ionic/react";
import useSWR from "swr";
import { GameInterface } from "../../types";
import { AxiosError, RequestError } from "../../types/request";
import { useFetcher } from "./useFetcher";
import { usePlayerSelf } from "./usePlayerSelf";

export function useNextGame() {
  const { getFetcher, getFirstError } = useFetcher();
  const [present] = useIonToast();
  const { inGroup } = usePlayerSelf();

  const { error, data, mutate } = useSWR<
    NextGameResponse,
    NextGameErrorResponse
  >(inGroup ? `/games/next` : undefined, getFetcher, {
    onError(err) {
      present({
        position: "middle",
        color: "danger",
        duration: 2000,
        message: getFirstError(err) || "NEXT GAME ERROR",
      });
    },
  });

  return {
    isLoading: !error && !data,
    isError: error,
    data,
    mutate,
  };
}

type NextGameResponse = Pick<
  GameInterface,
  "id" | "name" | "game_number" | "game_time" | "location"
> & {
  last_game_time: string;
};

type NextGameErrors = RequestError[0];

interface NextGameErrorResponse extends AxiosError<NextGameErrors> {}
