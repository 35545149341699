import { Color } from "@ionic/core";
import { IonText } from "@ionic/react";
import {
  accessibilityOutline,
  addOutline,
  bandageOutline,
  beerOutline,
  bonfireOutline,
  cartOutline,
  cashOutline,
  chatbubbleOutline,
  diceOutline,
  happyOutline,
  helpOutline,
  iceCreamOutline,
  searchOutline,
  skullOutline,
  sparklesOutline,
  thunderstormOutline,
  trophyOutline,
  warningOutline,
  waterOutline,
} from "ionicons/icons";
import { GroupEvent } from "../contexts/GroupEventsContext";
import {
  AppliedModifier,
  AppliedModifierEffects,
  GameThrowInterface,
  LocationInterface,
  LocationModifier,
  PlayerInterface,
  ShopItemInterface,
} from "../types";

// eslint-disable-next-line
const DATE_REGEX = /\d*\/\d*\/\d*\s\-\s\d*\:\d*|\d*\/\d*\/\d*/g;

/**
 * Matches:
 * browse page - '12/04/2021 - 08:08'
 * search page - '12/04/2021'
 */
export function matchDate(value: string) {
  const match = value.match(DATE_REGEX);
  if (!match || match.length === 0) return null;

  // eslint-disable-next-line
  let [date, time] = match[0].split(" - ");
  let [day, month, year] = date.split("/");
  const dateObj = new Date(`${year}-${month}-${day}`);

  if (time) {
    let [hour, minute] = time.split(":");
    dateObj.setHours(parseInt(hour, 10));
    dateObj.setMinutes(parseInt(minute, 10));
  }

  return dateObj;
}

export function formatTimeAt(date: string) {
  if (!date) return null;
  return new Date(date).toLocaleTimeString("en-AU", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });
}

// e.g, 15 June
export function formatSubmittedAtDay(date: string) {
  if (!date) return null;
  return new Date(date).toLocaleDateString("en-AU", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
}

//
export function formatLongSubmittedAt(date: string) {
  if (!date) return null;
  return new Date(date).toLocaleDateString("en-AU", {
    hour: "2-digit",
    minute: "2-digit",
    month: "short",
    day: "numeric",
    weekday: "long",
    year: "numeric",
  });
}

const relativeTimePeriods = [
  [31536000, "year"],
  [2419200, "month"],
  [604800, "week"],
  [86400, "day"],
  [3600, "hour"],
  [60, "minute"],
  [1, "second"],
];

export function relativeTime(dateString: string, isUtc = true) {
  const date = new Date(dateString);
  const seconds = ((new Date() as any) - (date as any)) / 1000;
  for (let [secondsPer, name] of relativeTimePeriods) {
    if (seconds >= secondsPer) {
      const amount = Math.floor(seconds / (secondsPer as number));
      return `${amount} ${name}${amount ? "s" : ""} ago`;
    }
  }
  return "Today";
}

export function getScoreModifierChip(
  scoreModifier: GameThrowInterface["score_modifier"]
): null | { icon: string; color: string } {
  switch (scoreModifier) {
    case "jackpot":
      return {
        icon: modifiers.jackpot.icon,
        color: "warning",
      };
    case "solo":
      return {
        icon: modifiers.solo.icon,
        color: "primary",
      };
    case "double_or_nothing":
      return {
        icon: modifiers.double_or_nothing.icon,
        color: "success",
      };
    case "mr_sucky":
      return {
        icon: modifiers.mr_sucky.icon,
        color: "secondary",
      };
    default:
      return null;
  }
}

export function getScoreColor(score: number) {
  return {
    1: "common",
    2: "common",
    3: "common",
    4: "uncommon",
    5: "uncommon",
    6: "rare",
    7: "rare",
    8: "epic",
    9: "epic",
    10: "legendary",
  }[score];
}

export const modifiers: {
  [key: string]: {
    id: PlayerInterface["score_modifier"];
    icon: string;
    name: string;
  };
} = {
  jackpot: { id: "jackpot", name: "Jackpot", icon: cashOutline },
  solo: { id: "solo", name: "Solo", icon: addOutline },
  double_or_nothing: {
    id: "double_or_nothing",
    name: "x2 or 0",
    icon: iceCreamOutline,
  },
  mr_sucky: { id: "mr_sucky", name: "Mr Sucky", icon: skullOutline },
};

export const modifiersArray = Object.values(modifiers);

export function formatNumber(number: number) {
  return number.toLocaleString("en-US");
}

export function formatGroupEventmessage(
  groupEvent: GroupEvent,
  selfUniqueId: PlayerInterface["unique_id"]
) {
  const messageType = appliedModifiers[groupEvent.modifier];

  if (!messageType) return;

  return messageType.buildMessage({
    self: selfUniqueId,
    source: groupEvent.source_player as string,
    target: groupEvent.target_player as string,
    meta: {},
    modifier: groupEvent.modifier,
  });
}

function colorName(self: string, name: string, type: "source" | "target") {
  let color: Color = type === "source" ? "primary" : "warning";
  if (name === self) {
    color = "success";
  }
  return <IonText color={color}>{name}</IonText>;
}

type GroupEventBuilder<T extends string> = {
  [key in T]: {
    id: T;
    icon: string;
    name: string;
    color: string;
    buildMessageWithEffects?: boolean;
    buildMessage: ({
      self,
      source,
      target,
      meta,
    }: {
      self: string;
      source: string;
      target: string;
      modifier: string;
      meta: Record<string, string | number>;
    }) => JSX.Element | string;
  };
};

export const appliedModifiers: GroupEventBuilder<AppliedModifier["modifier"]> =
{
  critical_roll: {
    id: "critical_roll",
    name: "Critical Roll",
    color: "success",
    icon: trophyOutline,
    buildMessage: ({ self, source }) => {
      return (
        <>{colorName(self, source, "source")} rolled a critical roll!! :D</>
      );
    },
  },
  critical_zero: {
    id: "critical_zero",
    name: "Critical Zero",
    color: "danger",
    icon: bandageOutline,
    buildMessage: ({ self, source }) => {
      return (
        <>
          {colorName(self, source, "source")} rolled a critical zeronope!! D:
        </>
      );
    },
  },
  spot_luck: {
    id: "spot_luck",
    name: "Spot Luck",
    color: "warning",
    icon: diceOutline,
    buildMessage: ({ self, source }) => {
      return <>{colorName(self, source, "source")} has some spot lukk</>;
    },
  },
  chinese_luck: {
    id: "chinese_luck",
    name: "中国人 Luck",
    color: "danger",
    icon: diceOutline,
    buildMessage: ({ self, source }) => {
      return (
        <>{colorName(self, source, "source")} rolled some chinese luck 888)</>
      );
    },
  },
  mr_sucky_victim: {
    id: "mr_sucky_victim",
    name: "Mr Sucky Victim",
    color: "danger",
    icon: waterOutline,
    buildMessageWithEffects: true,
    buildMessage: ({ self, source, meta }) => {
      return meta ? (
        <>
          {colorName(self, source, "source")} suckied{" "}
          <span style={{ fontWeight: "bold" }}>{meta.score_stolen}</span>, and
          gave <span style={{ fontWeight: "bold" }}>{meta.score_given}</span>
        </>
      ) : (
        <>
          {colorName(self, source, "source")} suckied your score away from you
        </>
      );
    },
  },
  mr_sucky_perpetrator: {
    id: "mr_sucky_perpetrator",
    name: "Mr Sucky Perpetrator",
    color: "success",
    icon: skullOutline,
    buildMessageWithEffects: true,
    buildMessage: ({ self, source, meta }) => {
      return meta ? (
        <>
          suckied{" "}
          <span style={{ fontWeight: "bold" }}>{meta.score_stolen}</span> from{" "}
          {colorName(self, source, "source")}, and gave{" "}
          <span style={{ fontWeight: "bold" }}>{meta.score_given}</span>
        </>
      ) : (
        <>suckied score from {colorName(self, source, "source")}</>
      );
    },
  },
  poisoned: {
    id: "poisoned",
    name: "Poisoned",
    color: "success",
    icon: warningOutline,
    buildMessage: ({ self, source, target }) => {
      return (
        <>
          {colorName(self, source, "source")} poisioned{" "}
          {colorName(self, target, "target")}
        </>
      );
    },
  },
  cursed: {
    id: "cursed",
    name: "Cursed",
    color: "warning",
    icon: sparklesOutline,
    buildMessage: ({ self, target }) => {
      return <>{colorName(self, target, "target")} is cursed!</>;
    },
  },
  player_joined: {
    id: "player_joined",
    name: "Player joined",
    color: "success",
    icon: accessibilityOutline,
    buildMessage: ({ self, target }) => {
      return <>{colorName(self, target, "target")} joined the game!</>;
    },
  },
  player_left: {
    id: "player_left",
    name: "Player left",
    color: "success",
    icon: accessibilityOutline,
    buildMessage: ({ self, target }) => {
      return <>{colorName(self, target, "target")} left everyone behind :(</>;
    },
  },
  cure_poison: {
    id: "cure_poison",
    name: "Cured Poison!",
    color: "success",
    icon: beerOutline,
    buildMessage: ({ self, source }) => {
      return <>{colorName(self, source, "target")} cured his poison!!!</>;
    },
  },
  suckers_luck: {
    id: "suckers_luck",
    name: "Suckers Luck!",
    color: "success",
    icon: beerOutline,
    buildMessage: ({ self, source }) => {
      return (
        <>
          {colorName(self, source, "target")} unlocked{" "}
          <span className="text-effect--disco-party">SUCKERS!! LUCK!!</span>
        </>
      );
    },
  },
  poison_random_player: {
    id: "poison_random_player",
    icon: warningOutline,
    name: "Poison Random Player",
    color: "warning",
    buildMessage: ({ self, source, target }) => {
      return <>{colorName(self, source, "source")} randomly poisoned {colorName(self, target, "target")}</>;
    }
  }
};

export const shopItemIcons: { [key in ShopItemInterface["id"]]: string } = {
  cure_poison: appliedModifiers.poisoned.icon,
  poison_random_player: appliedModifiers.poisoned.icon,
  suckers_luck: beerOutline,
  give_me_a_catch_phrase: chatbubbleOutline,
};

export const appliedModifiersEffects: {
  [key: string]: {
    id: AppliedModifierEffects;
    name: string;
  };
} = {
  zero_player_score: {
    id: "zero_player_score",
    name: "Zero Player Score :(",
  },
  triple_score_awarded: {
    id: "triple_score_awarded",
    name: "Triple! Score! Bonus!!!",
  },
  plus_10_percent: {
    id: "plus_10_percent",
    name: "+10%",
  },
  minus_10_percent: {
    id: "minus_10_percent",
    name: "-10%",
  },
  plus_88: {
    id: "plus_88",
    name: "Plus 88 Points",
  },
  minus_1: {
    id: "minus_1",
    name: "-1 Point",
  },
};

type GameLocationModifierBuilder = {
  [key in LocationModifier["name"]]: {
    id: LocationModifier["name"];
    icon: string;
    name: string;
    color: string;
    buildMessage: ({
      location_type,
      name,
    }: {
      location_type: string;
      name: string;
    }) => JSX.Element | string;
  };
};

export const locationModifiers: GameLocationModifierBuilder = {
  poison_rain: {
    id: "poison_rain",
    name: "Poision",
    color: "success",
    icon: thunderstormOutline,
    buildMessage: () => {
      return <>pitter patter its raining poison</>;
    },
  },
  good_vibes: {
    id: "good_vibes",
    name: "Good Vibes",
    color: "success",
    icon: happyOutline,
    buildMessage: () => {
      return <>Good vibes only brah</>;
    },
  },
  spookey_area: {
    id: "spookey_area",
    name: "Spookey Area",
    color: "success",
    icon: skullOutline,
    buildMessage: () => {
      return <>ooOOoOOoOoooooOoOo</>;
    },
  },
};

type GameLocationBuilder = {
  [key in LocationInterface["location_type"]]: {
    id: LocationInterface["location_type"];
    icon: string;
    color: string;
    buildMessage: ({
      location_type,
      name,
    }: {
      location_type: string;
      name: string;
    }) => JSX.Element | string;
  };
};

export const locations: GameLocationBuilder = {
  exploring: {
    id: "exploring",
    icon: searchOutline,
    color: "success",
    buildMessage: ({ location_type, name }) => {
      return <>Exploring {name}</>;
    },
  },
  resting: {
    id: "resting",
    icon: bonfireOutline,
    color: "primary",
    buildMessage: ({ location_type, name }) => {
      return <>Resting in {name}</>;
    },
  },
  shopping: {
    id: "shopping",
    icon: cartOutline,
    color: "info",
    buildMessage: ({ location_type, name }) => {
      return <>Shopping at {name}</>;
    },
  },
  questing: {
    id: "questing",
    icon: helpOutline,
    color: "warning",
    buildMessage: ({ location_type, name }) => {
      return <>Question for {name}</>;
    },
  },
};
