import { Storage } from "@capacitor/storage";

const KEY = "token";

export const getAuthTokenStorage = async (): Promise<string | null> => {
  const token = await Storage.get({ key: KEY });
  if (!token.value) return null;
  return JSON.parse(token.value);
};

export const setAuthTokenStorage = async (value: string | null) => {
  await Storage.set({
    key: KEY,
    value: JSON.stringify(value),
  });
};
