import {
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonNote,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import {
  exitOutline,
  pulseOutline
} from "ionicons/icons";
import _ from "lodash";
import { useRef } from "react";
import { MAX_BOTS } from "../helpers/constants";
import { useGroupAddBot } from "../helpers/requests/useGroupAddBot";
import { useGroupLeave } from "../helpers/requests/useGroupLeave";
import { useGroupPlayers } from "../helpers/requests/useGroupPlayers";
import { useGroupUpdate } from "../helpers/requests/useGroupUpdate";
import { GroupInterface } from "../types";

type Props = {
  modalOpenString: string;
  group: Pick<GroupInterface, "id" | "name" | "frequency_in_seconds">;
};

const GroupSettingsModal = ({ group, modalOpenString }: Props) => {
  const modal = useRef<HTMLIonModalElement>(null);

  function handleCloseModal() {
    modal.current?.dismiss();
  }

  const { players } = useGroupPlayers();
  const { addBot } = useGroupAddBot();
  const { updateGroup } = useGroupUpdate(group.id);
  const { leaveGame, isLoading: leaveGameIsLoading } = useGroupLeave({ handleOnSuccess: handleCloseModal });
  const currentSpeed = useRef(group.frequency_in_seconds)

  const [bots] = _.partition(players, "bot");

  const maxBotsReached = bots.length >= MAX_BOTS;

  async function handleLeaveGame() {
    leaveGame(null);
  }

  function handleUpdateGroup(value: any) {
    if (value === undefined) return;
    const intValue = parseInt(value, 10)
    if (intValue === currentSpeed.current) return
    currentSpeed.current = intValue;

    updateGroup({ frequency_in_seconds: intValue });
  }

  return (
    <IonModal
      ref={modal}
      trigger={modalOpenString}
      initialBreakpoint={0.5}
      showBackdrop
      handleBehavior="cycle"
      breakpoints={[0, 0.25, 0.5, 0.75]}
      handle={true}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton color="medium" onClick={handleCloseModal}>
              Close
            </IonButton>
          </IonButtons>
          <IonTitle>
            {group.name} <IonNote>id: {group.id}</IonNote>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList className="margin-10-bottom">
          <IonItem
            button
            onClick={addBot}
            detail={false}
            disabled={maxBotsReached}
          >
            <IonIcon slot="start" icon={pulseOutline} />
            <IonLabel>Add bot</IonLabel>
            <IonChip slot="end">{bots.length} / {MAX_BOTS}</IonChip>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>Speed</IonLabel>
            <IonSelect
              value={currentSpeed.current}
              interface="popover"
              onIonChange={(e) => handleUpdateGroup(e.detail.value as number)}
            // disabled={updateGroupIsLoading}
            >
              <IonSelectOption value={10}>10s</IonSelectOption>
              <IonSelectOption value={30}>30s</IonSelectOption>
              <IonSelectOption value={60}>60s</IonSelectOption>
              <IonSelectOption value={90}>90s</IonSelectOption>
              <IonSelectOption value={120}>120s</IonSelectOption>
            </IonSelect>
          </IonItem>
        </IonList>
        <IonList>
          <IonItem
            button
            onClick={handleLeaveGame}
            color="danger"
            detail={false}
            disabled={leaveGameIsLoading}
          >
            <IonIcon slot="end" icon={exitOutline} />
            <IonLabel>Leave Group</IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default GroupSettingsModal;
