import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenuButton,
  IonPage,
  IonPopover,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToggle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import {
  barChartOutline,
  chatboxOutline,
  logOutOutline,
  moonOutline,
} from "ionicons/icons";
import { useContext } from "react";
import { HexColorPicker } from "react-colorful";
import { AppSettingsContext } from "../contexts/AppSettingsContext";
import { AuthContext } from "../contexts/AuthContext";
import { usePlayerSelf } from "../helpers/requests/usePlayerSelf";
import { usePlayerUpdate } from "../helpers/requests/usePlayerUpdate";
import { PlayerInterface } from "../types";

const GroupsView = () => {
  const [presentAlert] = useIonAlert();
  const { removeAuth } = useContext(AuthContext);
  const { appSettings, setSingleAppSetting } = useContext(AppSettingsContext);
  const { player, mutate: mutatePlayerSelf } = usePlayerSelf();
  const { updatePlayer } = usePlayerUpdate();

  async function handleUpdateChartColor(
    chartColor: PlayerInterface["chart_color"]
  ) {
    await updatePlayer({ chart_color: chartColor });
    mutatePlayerSelf((data) => data && { ...data, chart_color: chartColor });
  }

  async function handleUpdateCatchPhrase(
    newPrimaryCatchphrase: PlayerInterface["catchphrase"]
  ) {
    await updatePlayer({ primary_catchphrase: newPrimaryCatchphrase });
    mutatePlayerSelf(
      (data) => data && { ...data, catchphrase: newPrimaryCatchphrase }
    );
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Profile</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList className="margin-10-bottom padding-10-bottom">
          <IonListHeader>
            <IonLabel>Settings</IonLabel>
          </IonListHeader>
          <IonItem
            onClick={() =>
              setSingleAppSetting({ darkmode: !appSettings.darkmode })
            }
            lines="none"
          >
            <IonIcon icon={moonOutline} slot="start"></IonIcon>
            <IonLabel>DarkMode</IonLabel>
            <IonToggle slot="end" checked={appSettings.darkmode}></IonToggle>
          </IonItem>
          <IonItem lines="none">
            <IonIcon icon={barChartOutline} slot="start"></IonIcon>
            <IonLabel>Chart Line Colour</IonLabel>
            <div
              className="custom-botton"
              slot="end"
              style={{ backgroundColor: player?.chart_color }}
              id="chart-line-color-popover"
            >
              {player?.chart_color}
            </div>
            <IonPopover
              trigger="chart-line-color-popover"
              triggerAction="click"
            >
              <IonContent class="ion-padding">
                <HexColorPicker
                  color={player?.chart_color}
                  onChange={(newColor) => {
                    handleUpdateChartColor(newColor);
                  }}
                />
              </IonContent>
            </IonPopover>
          </IonItem>
          {player?.catchphrase && (
            <IonItem lines="none">
              <IonIcon icon={chatboxOutline} slot="start"></IonIcon>
              <IonLabel>
                Catchphrase<p>{player.catchphrase}</p>
              </IonLabel>
              <IonSelect
                slot="end"
                interface="alert"
                onIonChange={(e) => {
                  handleUpdateCatchPhrase(e.detail.value);
                }}
              >
                {player.catchphrases.map((catchphrase) => (
                  <IonSelectOption value={catchphrase} key={catchphrase}>
                    {catchphrase}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          )}
        </IonList>
        <IonList>
          <IonItem
            button
            onClick={() =>
              presentAlert({
                header:
                  "Are you sure you want to logout❓❓❓  you need to know your password!!",
                buttons: [
                  {
                    text: "no no no  no",
                    role: "cancel",
                  },
                  {
                    text: "yes plz",
                    role: "confirm",
                    handler: removeAuth,
                  },
                ],
              })
            }
            detail={true}
            lines="none"
            color="danger"
          >
            <IonIcon icon={logOutOutline} slot="start"></IonIcon>
            <IonLabel>Logout</IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default GroupsView;
