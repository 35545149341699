import { IonCardSubtitle, IonIcon, IonText } from "@ionic/react";
import { motion, useAnimationControls } from "framer-motion";
import { heart } from "ionicons/icons";
import _ from "lodash";
import { useState } from "react";
import { shopItemIcons } from "../../helpers/formatters";
import { useShopBuyItem } from "../../helpers/requests/useShopBuyItem";
import { ShopItemInterface } from "../../types";

type Props = {
  shopItem: Pick<ShopItemInterface, "id" | "name" | "price">;
};
const colors = [
  "primary",
  "secondary",
  "tertiary",
  "success",
  "warning",
  "danger",
];

function getNewColor(): string {
  return _.sample(colors) as string;
}

const ShopCardItem = ({ shopItem }: Props) => {
  const icon = shopItemIcons[shopItem.id];
  const controls = useAnimationControls();
  const { buyItem } = useShopBuyItem();
  const [iconColor, setIconColor] = useState<string>(getNewColor());

  function handleBuyItem() {
    buyItem({ id: shopItem.id });
    setIconColor(getNewColor());
    controls.start("perform");
  }

  return (
    <div className={`card-modifier`} onClick={handleBuyItem}>
      {icon && (
        <IonIcon
          icon={icon}
          style={{ paddingBottom: "5px", fontSize: "20px" }}
        />
      )}

      <IonText>
        <h3>{shopItem.name}</h3>
      </IonText>
      <IonCardSubtitle>{shopItem.price}$</IonCardSubtitle>
      <motion.div
        animate={controls}
        style={{ position: "absolute", opacity: 0 }}
        variants={{
          perform: {
            opacity: [0, 1, 0, 0],
            y: [-20, -40, -80, 0],
          },
        }}
      >
        <IonIcon icon={heart} color={iconColor} />
      </motion.div>
    </div>
  );
};

export default ShopCardItem;
