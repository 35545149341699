import {
  IonApp,
  IonContent,
  IonProgressBar,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import Axios from "axios";
import { configure } from "axios-hooks";
import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { SWRConfig } from "swr";
import Menu from "./components/Menu";
import { ActionCableChannelsProvider } from "./contexts/ActionCableChannelsContext";
import { ActionCableProvider } from "./contexts/ActionCableContext";
import { AppSettingsProvider } from "./contexts/AppSettingsContext";
import { AuthContext, AuthProvider } from "./contexts/AuthContext";
import { GroupEventsProvider } from "./contexts/GroupEventsContext";
import { PlayerProvider } from "./contexts/PlayerContext";
import { API_URL, appRoutes } from "./helpers/constants";
import Dashboard from "./pages/Dashboard";
import FriendsView from "./pages/FriendsView";
import GroupsView from "./pages/GroupsView";
import LoginView from "./pages/LoginView";
import ProfileView from "./pages/ProfileView";
import SignupSuccessfulView from "./pages/SignupSuccessfulView";
import SignupView from "./pages/SignupView";
import "./theme/styles.css";
import "./theme/variables.css";
import "./theme/texteffects.css";

const axios = Axios.create({ baseURL: API_URL });
configure({ axios });

setupIonicReact({
  mode: "ios",
});

const App: React.FC = () => {
  return (
    <IonApp>
      <AppStateWrapper>
        <AppRoutes />
      </AppStateWrapper>
    </IonApp>
  );
};

export default App;

type AppStateWrapperProps = {
  children: React.ReactNode;
};

const AppStateWrapper = ({ children }: AppStateWrapperProps) => {
  return (
    <AppSettingsProvider>
      <AuthProvider>{children}</AuthProvider>
    </AppSettingsProvider>
  );
};

type AppRequestWrapperProps = {
  children: React.ReactNode;
};

const AppRequestWrapper = ({ children }: AppRequestWrapperProps) => {
  const { removeAuth } = useContext(AuthContext);

  return (
    <SWRConfig
      value={{
        onError: (err) => {
          console.log("err", err);
          err.response.status === 401 && removeAuth?.();
        },
      }}
    >
      {children}
    </SWRConfig>
  );
};

const AppRoutes = () => {
  const { auth, initializing } = useContext(AuthContext);

  if (initializing) {
    return (
      <IonContent>
        <IonProgressBar type="indeterminate" />
      </IonContent>
    );
  }

  if (!auth) {
    return (
      <IonReactRouter>
        <IonRouterOutlet>
          <Route path={appRoutes.login} exact>
            <LoginView />
          </Route>
          <Route path={appRoutes.signup} exact>
            <SignupView />
          </Route>

          <Redirect to={{ pathname: appRoutes.login }} />
        </IonRouterOutlet>
      </IonReactRouter>
    );
  }

  return (
    <AppRequestWrapper>
      <ActionCableProvider>
        <GroupEventsProvider>
          <ActionCableChannelsProvider>
            <PlayerProvider>
              <IonReactRouter>
                <IonSplitPane contentId="main">
                  <Menu />
                  <IonRouterOutlet id="main">
                    <Route exact path={appRoutes.dashboard}>
                      <Dashboard />
                    </Route>
                    <Route path={appRoutes.signupSuccessful}>
                      <SignupSuccessfulView />
                    </Route>
                    {/* <Route path={appRoutes.games}>
                      <GamesView />
                    </Route> */}
                    <Route path={appRoutes.groups}>
                      <GroupsView />
                    </Route>
                    <Route path={appRoutes.friends}>
                      <FriendsView />
                    </Route>
                    <Route path={appRoutes.profile}>
                      <ProfileView />
                    </Route>
                    <Redirect
                      to={{
                        pathname: auth ? appRoutes.groups : appRoutes.login,
                      }}
                    />
                  </IonRouterOutlet>
                </IonSplitPane>
              </IonReactRouter>
            </PlayerProvider>
          </ActionCableChannelsProvider>
        </GroupEventsProvider>
      </ActionCableProvider>
    </AppRequestWrapper>
  );
};
