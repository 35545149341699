import useSWRMutation from "swr/mutation";
import { ShopItemInterface } from "../../types";
import { AxiosError } from "../../types/request";
import { useFetcher } from "./useFetcher";
import { usePlayerSelf } from "./usePlayerSelf";

type BuyItemAttributes = {
  id: ShopItemInterface["id"];
};

export function useShopBuyItem() {
  const { postFetcher, getFirstError } = useFetcher();
  const { mutate: refetchSelf } = usePlayerSelf();

  const { trigger, isMutating, error } = useSWRMutation<
    Response,
    ErrorResponse,
    string,
    BuyItemAttributes
  >("/shops/buy", (url: string, { arg }: any) => postFetcher(url, arg), {
    revalidate: false,
    populateCache: false,
    onSuccess: () => {
      refetchSelf();
    },
  });

  const errorMessage = getFirstError(error);

  return {
    isLoading: isMutating,
    buyItem: trigger,
    errorMessage,
  };
}

type Response = null;

type NotEnoughScoreError = {
  type: "not_enough_score";
  message: string;
};

type Errors = NotEnoughScoreError;

interface ErrorResponse extends AxiosError<Errors> {}
