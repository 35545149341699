import {
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonNote,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import _ from "lodash";
import { Fragment, useEffect, useRef } from "react";
import {
  appliedModifiers,
  appliedModifiersEffects,
} from "../helpers/formatters";
import { useGroupPlayers } from "../helpers/requests/useGroupPlayers";
import { GameThrowInterface, PlayerInterface } from "../types";
import AppliedModifierChip from "./AppliedModifierChip";

export type PlayerGameThrowModalItem = Pick<
  GameThrowInterface,
  "applied_modifiers"
> & {
  player: Pick<PlayerInterface, "id" | "unique_id">;
};

type Props = {
  modalOpenString: string;
  gameThrow: PlayerGameThrowModalItem | null;
  onClose: () => void;
};

const PlayerGameRoundModal = ({
  gameThrow,
  modalOpenString,
  onClose,
}: Props) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const { players } = useGroupPlayers();

  function handleCloseModal() {
    modal.current?.dismiss();
    onClose?.();
  }

  useEffect(() => {
    if (!!gameThrow) {
      modal.current?.present();
    } else if (!gameThrow) {
      handleCloseModal();
    }
  }, [gameThrow]);

  const playerSelf = players.find((p) => p.is_self);

  const groupedModifiers = gameThrow
    ? _.groupBy(gameThrow.applied_modifiers, (applied_modifier) => [
        applied_modifier.modifier,
        applied_modifier.cause,
      ])
    : {};

  return (
    <IonModal
      ref={modal}
      trigger={modalOpenString}
      initialBreakpoint={0.6}
      showBackdrop
      handleBehavior="cycle"
      breakpoints={[0, 0.25, 0.5, 0.75]}
      handle={true}
      onDidDismiss={onClose}
    >
      {gameThrow && (
        <>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton color="medium" onClick={handleCloseModal}>
                  Close
                </IonButton>
              </IonButtons>
              <IonTitle>{gameThrow.player.unique_id}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonList>
              {gameThrow.applied_modifiers.length === 0 && (
                <IonList>
                  <IonItem lines="none">
                    <IonLabel>Clean run! 🏃</IonLabel>
                  </IonItem>
                </IonList>
              )}
              {Object.values(groupedModifiers).map((modifiers, index) => {
                const appliedModifier = modifiers[0];
                const modifier = appliedModifiers[appliedModifier.modifier];
                const total = modifiers.length;

                const message =
                  playerSelf &&
                  modifier?.buildMessage({
                    self: playerSelf.unique_id,
                    source: appliedModifier.cause,
                    target: gameThrow.player.unique_id,
                    meta: appliedModifier.meta || {},
                    modifier: appliedModifier.modifier,
                  });

                return (
                  <Fragment key={`ami-${index}`}>
                    <IonItem lines="none">
                      <IonLabel
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {modifier.name}

                        {total > 1 && (
                          <IonNote style={{ marginLeft: "5px" }}>
                            x{total}
                          </IonNote>
                        )}

                        {["location", "roll"].includes(
                          appliedModifier.cause
                        ) && (
                          <IonBadge
                            color="medium"
                            style={{ marginLeft: "5px" }}
                          >
                            {appliedModifier.cause}
                          </IonBadge>
                        )}
                      </IonLabel>
                      <AppliedModifierChip appliedModifier={appliedModifier} />
                    </IonItem>

                    {/* Modifier Message */}
                    {modifier.buildMessageWithEffects && message && (
                      <IonItem
                        lines="none"
                        key={`ami-${index}-ame-${modifier.id}`}
                      >
                        <div slot="start"></div>
                        <IonLabel color="medium">{message}</IonLabel>
                      </IonItem>
                    )}

                    {/* Applied Modifier Messages */}
                    {appliedModifier.effects.length > 0 &&
                      appliedModifier.effects.map((amEffect, index2) => {
                        const appliedModifierEffect =
                          appliedModifiersEffects[amEffect];
                        return (
                          <IonItem
                            lines="none"
                            key={`ami-${index}-ame-${index2}`}
                          >
                            <div slot="start"></div>
                            <IonLabel color="medium">
                              {appliedModifierEffect.name}
                            </IonLabel>
                          </IonItem>
                        );
                      })}
                  </Fragment>
                );
              })}
            </IonList>
          </IonContent>
        </>
      )}
    </IonModal>
  );
};

export default PlayerGameRoundModal;
