import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import { heartHalf, heartOutline, trashOutline } from "ionicons/icons";
import { useRef } from "react";
import { useFriendAdd } from "../helpers/requests/useFriendAdd";
import { useFriendRemove } from "../helpers/requests/useFriendRemove";
import { useGroupBotKick } from "../helpers/requests/useGroupBotKick";
import { useGroupPlayers } from "../helpers/requests/useGroupPlayers";
import { PlayerInterface, TargetedModifier } from "../types";
import CursedItem from "./CursedItem";
import Notice from "./Notice";
import PoisonItem from "./PoisonItem";

type Props = {
  modalOpenString: string;
  player: Pick<
    PlayerInterface,
    "id" | "unique_id" | "bot" | "is_friend" | "is_self" | "catchphrase"
  > & {
    targeted_modifiers_target: Pick<
      TargetedModifier,
      "id" | "modifier" | "source_player_id" | "target_player_id"
    >;
  };
};

const PlayerInteractionModal = ({ player, modalOpenString }: Props) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const { addFriend, isLoading: addFriendIsLoading } = useFriendAdd();
  const { removeFriend, isLoading: removeFriendIsLoading } = useFriendRemove();
  const { kickBot, isLoading: kickBotIsLoading } = useGroupBotKick();
  const { mutate: mutateGroupPlayers } = useGroupPlayers();
  const [present] = useIonToast();

  function mutateFriendvalue(value: boolean) {
    mutateGroupPlayers((data) =>
      data?.map((groupPlayer) => {
        if (groupPlayer.id === player.id) {
          groupPlayer.is_friend = value;
        }
        return groupPlayer;
      })
    );
  }

  function handleAddToFriend() {
    addFriend(
      { unique_id: player.unique_id },
      { onSuccess: () => mutateFriendvalue(true) }
    );
  }

  function handleRemoveToFriend() {
    removeFriend(
      { unique_id: player.unique_id },
      { onSuccess: () => mutateFriendvalue(false) }
    );
  }

  function handleKickBot() {
    kickBot(
      { unique_id: player.unique_id },
      {
        onSuccess: () => {
          present({
            position: "middle",
            color: "success",
            duration: 2000,
            message: `Removed the dumb bot.`,
          });

          mutateGroupPlayers((data) => {
            if (data) {
              data.filter((player) => player.id);
              return data;
            }
          });

          handleCloseModal();
        },
      }
    );
  }

  function handleCloseModal() {
    modal.current?.dismiss();
  }

  return (
    <IonModal
      ref={modal}
      trigger={modalOpenString}
      initialBreakpoint={0.5}
      showBackdrop
      handleBehavior="cycle"
      breakpoints={[0, 0.25, 0.5, 0.75]}
      handle={true}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton color="medium" onClick={handleCloseModal}>
              Close
            </IonButton>
          </IonButtons>
          <IonTitle>{player.unique_id}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {player.is_self && (
          <div className="padding-10-bottom">
            <Notice type="primary">Say hi to yourself ;-D</Notice>
          </div>
        )}

        {player.catchphrase && <div className="catchphrase">
          {player.catchphrase}
        </div>}

        <IonList className="margin-10-bottom">
          <PoisonItem player={player} />
          <CursedItem player={player} />
        </IonList>

        <IonList>
          {!player.is_self && player.is_friend && (
            <IonItem
              lines="none"
              button
              onClick={handleRemoveToFriend}
              detail={false}
              disabled={removeFriendIsLoading}
            >
              <IonIcon slot="end" icon={heartHalf} />
              <IonLabel>UnFriend :(</IonLabel>
            </IonItem>
          )}
          {!player.is_self && !player.is_friend && (
            <IonItem
              lines="none"
              button
              onClick={handleAddToFriend}
              detail={false}
              disabled={addFriendIsLoading}
            >
              <IonIcon slot="start" icon={heartOutline} color="danger" />
              <IonLabel>Add Friend :)))</IonLabel>
            </IonItem>
          )}
          {player.bot && (
            <IonItem
              lines="none"
              button
              onClick={handleKickBot}
              detail={false}
              disabled={kickBotIsLoading}
            >
              <IonIcon slot="start" icon={trashOutline} color="warning" />
              <IonLabel>Kick Bot</IonLabel>
            </IonItem>
          )}
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default PlayerInteractionModal;
