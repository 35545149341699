import useSWR from "swr";
import { GroupInterface, PlayerInterface } from "../../types";
import { AxiosError, RequestError } from "../../types/request";
import { env } from "../constants";
import { useFetcher } from "./useFetcher";

export function usePlayerSelf() {
  const { getFetcher } = useFetcher();

  const { error, data, mutate } = useSWR<
    PlayerSelfResponse,
    PlayerSelfErrorResponse
  >(`/players/self`, getFetcher, {
    refreshInterval: env.fetchPlayerSelf,
  });

  const playerNotInGroup = data ? !data.group : false;
  const playerInGroup = !!data?.group;

  return {
    isLoading: !error && !data,
    isError: error,
    player: data,
    inGroup: !!data?.group,
    playerNotInGroup,
    playerInGroup,
    mutate,
  };
}

export type PlayerSelfResponse = Pick<
  PlayerInterface,
  | "id"
  | "name"
  | "unique_id"
  | "score_total"
  | "score_modifier"
  | "chart_color"
  | "catchphrases"
  | "catchphrase"
> & {
  group: Pick<GroupInterface, "id" | "name"> | undefined;
};

interface PlayerSelfErrorResponse extends AxiosError<RequestError[]> {}
