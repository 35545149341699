import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonMenuButton,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { heartHalf } from "ionicons/icons";
import Notice from "../components/Notice";
import { useFriendRemove } from "../helpers/requests/useFriendRemove";
import { useFriendsList } from "../helpers/requests/useFriendsList";
import { PlayerInterface } from "../types";
import FriendAdd from "./dashboard/FriendAdd";

const GroupsView = () => {
  const { friends, mutateFriendsList } = useFriendsList();
  const { removeFriend } = useFriendRemove();

  useIonViewWillEnter(() => {
    mutateFriendsList();
  });

  function handleRemoveFriend(friendUniqueId: PlayerInterface["unique_id"]) {
    removeFriend({ unique_id: friendUniqueId });
  }

  const hasFriends = friends && friends.length > 0;

  const orderedFriendsByScore =
    hasFriends && friends.sort((a, b) => b.score_total - a.score_total);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Friends</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList className="ion-margin-bottom">
          <FriendAdd />
        </IonList>
        <IonList>
          {orderedFriendsByScore &&
            orderedFriendsByScore.map((player) => {
              return (
                <IonItemSliding>
                  <IonItemOptions side="start">
                    <IonItemOption
                      color="danger"
                      expandable
                      onClick={() => handleRemoveFriend(player.unique_id)}
                    >
                      <IonIcon slot="icon-only" icon={heartHalf} />
                      Unfriend
                    </IonItemOption>
                  </IonItemOptions>

                  <IonItem key={`player-${player.id}`}>
                    <IonNote slot="start">{player.score_total}</IonNote>
                    <IonLabel>{player.unique_id}</IonLabel>
                    {player.group && (
                      <IonNote slot="end">{player.group.name}</IonNote>
                    )}
                  </IonItem>
                </IonItemSliding>
              );
            })}
        </IonList>
        {!hasFriends && (
          <div className="ion-padding-top">
            <Notice type="error">You have no friends. :(</Notice>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default GroupsView;
