import useSWR from "swr";
import { GroupInterface, PlayerInterface } from "../../types";
import { AxiosError, RequestError } from "../../types/request";
import { useFetcher } from "./useFetcher";

export function useFriendsList() {
  const { getFetcher } = useFetcher();

  const { error, data, mutate } = useSWR<
    FriendListResponse,
    FriendListErrorResponse
  >(`/friends`, getFetcher, {
    revalidateOnFocus: false,
  });

  return {
    isLoading: !error && !data,
    isError: error,
    friends: data,
    mutateFriendsList: mutate,
  };
}

type Friend = Pick<
  PlayerInterface,
  "id" | "name" | "unique_id" | "score_total"
> & {
  group?: Pick<
    GroupInterface,
    "id" | "name" | "password_protected" | "players_count"
  >;
};

type FriendListResponse = Friend[];

interface FriendListErrorResponse extends AxiosError<RequestError[]> { }
