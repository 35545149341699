import { useContext } from "react";
import useSWRMutation from "swr/mutation";
import { ActionCableChannelsContext } from "../../contexts/ActionCableChannelsContext";
import { GroupInterface } from "../../types";
import { AxiosError } from "../../types/request";
import { useDashboard } from "./useDashboard";
import { useFetcher } from "./useFetcher";
import { useNextGame } from "./useNextGame";
import { usePlayerSelf } from "./usePlayerSelf";

type CreateGroupAttributes = {
  name: GroupInterface["name"];
  password?: string;
  game_mode: GroupInterface["game_mode"];
};

export function useGroupCreate() {
  const { connectChannels } = useContext(ActionCableChannelsContext);
  const { postFetcher, getFirstError } = useFetcher();
  const { mutate: refetchDashboard } = useDashboard();
  const { mutate: refetchSelf } = usePlayerSelf();
  const { mutate: refetchNext } = useNextGame();

  const { trigger, isMutating, error } = useSWRMutation<
    CreateGroupResponse,
    CreateGroupErrorResponse,
    string,
    CreateGroupAttributes
  >("/groups", (url: string, { arg }: any) => postFetcher(url, arg), {
    revalidate: false,
    populateCache: false,
    onSuccess: async () => {
      await refetchSelf();
      refetchNext();
      await refetchDashboard();
      connectChannels();
    },
  });

  const errorMessage = getFirstError(error);

  return {
    isLoading: isMutating,
    createGroup: trigger,
    errorMessage,
  };
}

type CreateGroupResponse = Pick<
  GroupInterface,
  "id" | "name" | "password_protected" | "players_count"
>;

type InvalidNameError = {
  type: "invalid_name";
  message: string;
};

type UnknownError = {
  type: "unknown_group_create_error";
  message: string;
};

type CreateGroupErrors = InvalidNameError | UnknownError;

interface CreateGroupErrorResponse extends AxiosError<CreateGroupErrors> {}
