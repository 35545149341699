import {
  IonButton,
  IonContent,
  IonList,
  IonNote,
  IonPage,
  IonProgressBar,
  IonText,
} from "@ionic/react";
import { useContext, useState } from "react";
import Notice from "../components/Notice";
import SignupInput, { signupValidation } from "../components/SignupInput";
import SignupPasswordInput, {
  signupPasswordValidation,
} from "../components/SignupPasswordInput";
import { AuthContext } from "../contexts/AuthContext";
import { appRoutes } from "../helpers/constants";
import { useSignup } from "../helpers/requests/useSignup";

const SignupView = () => {
  const { auth } = useContext(AuthContext);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const { signup, isLoading, errorMessage } = useSignup();

  const enableSubmit = !!(
    name &&
    password &&
    !signupValidation(name) &&
    !signupPasswordValidation(password)
  );

  async function handleSignup() {
    signup({ name, password });
  }

  return (
    <IonPage>
      <IonContent className="ion-padding-start ion-padding-end">
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {auth && (
            <>
              <IonText>
                <p>You are already logged in!</p>
              </IonText>
              <div className="ion-margin-top">
                <IonButton color="success" routerLink={appRoutes.dashboard}>
                  Go to dashboard
                </IonButton>
              </div>
            </>
          )}
          {!auth && (
            <>
              <div>
                <IonNote>
                  <h5>give me your name and password</h5>
                </IonNote>
                {errorMessage && <Notice type="error">{errorMessage}</Notice>}

                <IonList className="ion-margin-top">
                  <SignupInput
                    value={name}
                    onChange={(value) => setName(value)}
                    handleEnter={handleSignup}
                  />
                  <SignupPasswordInput
                    value={password}
                    onChange={(value) => setPassword(value)}
                    handleEnter={handleSignup}
                  />
                </IonList>

                <div
                  className="ion-margin-top"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <IonButton
                    fill="clear"
                    size="small"
                    color="medium"
                    routerLink={appRoutes.login}
                  >
                    Or login
                  </IonButton>
                  <IonButton
                    color="primary"
                    onClick={handleSignup}
                    disabled={!enableSubmit}
                    style={{ width: "200px" }}
                  >
                    {isLoading ? (
                      <IonProgressBar type="indeterminate" />
                    ) : (
                      "Create!"
                    )}
                  </IonButton>
                </div>
              </div>
            </>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SignupView;
