import { Consumer, createConsumer } from "@rails/actioncable";
import React, { useContext, useEffect, useMemo } from "react";
import { env } from "../helpers/constants";
import { AuthContext } from "./AuthContext";

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

type IContextProps = {
  actionCable: Consumer | undefined;
};

const ActionCableContext = React.createContext({} as IContextProps);

const ActionCableProvider = (props: Props) => {
  const { auth } = useContext(AuthContext);

  const actionCable = useMemo(
    () =>
      auth ? createConsumer(`${env.websocketUrl}?token=${auth}`) : undefined,
    [auth]
  );

  useEffect(() => {
    return () => {
      actionCable?.disconnect();
    };
  }, []);

  return (
    <ActionCableContext.Provider
      value={{
        actionCable,
      }}
    >
      {props.children}
    </ActionCableContext.Provider>
  );
};

export { ActionCableContext, ActionCableProvider };
