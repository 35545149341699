import { IonInput, IonItem, IonLabel, IonNote } from "@ionic/react";
import { useState } from "react";

type Props = {
  onChange: (value: string) => void;
  handleEnter: () => Promise<void>;
  value: string;
  inputType: "password" | "text";
};

export function loginValidation(value: string): string | null {
  if (!value) {
    return "empty_input";
  } else {
    return null;
  }
}

const LoginInput = ({ onChange, value, handleEnter, inputType }: Props) => {
  const [validationError, setvalidationError] = useState<string | null>(null);

  function handleChange(newValue: string) {
    const validation = loginValidation(newValue);
    switch (validation) {
      case "empty_input":
        setvalidationError("Password cant be empty");
        break;
      default:
        setvalidationError(null);
        break;
    }

    onChange(newValue);
  }

  return (
    <IonItem>
      <IonLabel position="fixed">password</IonLabel>
      <IonInput
        autofocus
        title="Your secret passcode. This can not be restored if you lost it."
        type={inputType}
        required
        value={value}
        minlength={1}
        onIonChange={(e) => handleChange(e.detail.value?.toString() || '')}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            handleEnter?.();
          }
        }}
      />
      {validationError && (
        <IonNote slot="error" style={{ paddingBottom: "12px" }}>
          {validationError}
        </IonNote>
      )}
    </IonItem>
  );
};

export default LoginInput;
