import { useIonToast } from "@ionic/react";
import useSWRMutation from "swr/mutation";
import { PlayerInterface } from "../../types";
import { AxiosError } from "../../types/request";
import { PlayerSerializer } from "../../types/serializers";
import { useFetcher } from "./useFetcher";

type GroupKickBotArguments = {
  unique_id: PlayerInterface["unique_id"];
};

export function useGroupBotKick() {
  const { postFetcher, getFirstError } = useFetcher();

  const [present] = useIonToast();

  const { trigger, isMutating } = useSWRMutation<
    PlayerSerializer,
    GroupKickBotErrorResponse,
    string,
    GroupKickBotArguments
  >("/groups/kick_bot", (url: string, { arg }) => postFetcher(url, arg), {
    onError: (err) => {
      present({
        position: "middle",
        color: "danger",
        duration: 2000,
        message: getFirstError(err) || "Could not remove bot",
      });
    },
    revalidate: false, // auto revalidate after mutation
    populateCache: false, // write back the response to the cache after mutation
  });

  return {
    isLoading: isMutating,
    kickBot: trigger,
  };
}

type BotHigherScoreThanYouError = {
  type: "bot_higher_score_than_you";
  message: string;
};

type NotABotError = {
  type: "bot_is_not_a_bot";
  message: string;
};

type GroupKickBotErrors = NotABotError | BotHigherScoreThanYouError;

interface GroupKickBotErrorResponse extends AxiosError<GroupKickBotErrors[]> { }
