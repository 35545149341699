export const environment: {
  development: Environment;
  production: Environment;
} = {
  development: {
    apiUrl: "http://localhost:3000/api",
    websocketUrl: "ws://localhost:3000/api/cable",
    fetchNextGameInterval: 500,
    fetchDashboardInterval: 500,
    fetchGroupPlayersInterval: 60000,
    fetchGroupList: 60000,
    fetchPlayerSelf: 60000,
    fetchFriendsList: 60000 * 10,
  },
  production: {
    apiUrl: "https://api.number-humper.com/api",
    websocketUrl: "wss://api.number-humper.com/api/cable",
    fetchNextGameInterval: 1000,
    fetchDashboardInterval: 100,
    fetchGroupPlayersInterval: 60000,
    fetchGroupList: 60000,
    fetchPlayerSelf: 60000,
    fetchFriendsList: 60000 * 10,
  },
};

type Environment = {
  apiUrl: string;
  websocketUrl: string;
  fetchNextGameInterval: number;
  fetchDashboardInterval: number;
  fetchGroupPlayersInterval: number;
  fetchGroupList: number;
  fetchPlayerSelf: number;
  fetchFriendsList: number;
};
