import { useIonToast } from "@ionic/react";
import { useContext } from "react";
import { useHistory } from "react-router";
import useSWRMutation from "swr/mutation";
import { AuthContext } from "../../contexts/AuthContext";
import { AxiosError } from "../../types/request";
import { appRoutes } from "../constants";
import { useFetcher } from "./useFetcher";

export function useSignup() {
  const history = useHistory();
  const { updateAuth } = useContext(AuthContext);
  const { postFetcher, getFirstError } = useFetcher();
  const [present] = useIonToast();

  const { trigger, isMutating, error, data } = useSWRMutation<
    SignupResponse,
    SignupError
  >("/players", (url: string, { arg }: any) => postFetcher(url, arg), {
    onSuccess: async (data) => {
      await updateAuth(data.token);
      history.push(appRoutes.signupSuccessful);
    },
    onError: async (data) => {
      present({
        position: "middle",
        color: "danger",
        duration: 2000,
        message: "An error occured. Please try again.",
      });
    },
    revalidate: false,
    populateCache: false,
  });

  const errorMessage = getFirstError(error);

  return {
    isLoading: isMutating,
    signup: trigger,
    errorMessage,
    data,
  };
}

type SignupResponse = {
  id: number;
  name: string;
  token: string;
  password: string;
  unique_id: string;
};

type InvalidNameError = {
  type: "invalid_name";
  message: string;
};

type InvalidPasswordError = {
  type: "invalid_password";
  message: string;
};

type SignupErrors = InvalidNameError | InvalidPasswordError;

interface SignupError extends AxiosError<SignupErrors> {}
