import { IonInput, IonItem, IonLabel, IonNote } from "@ionic/react";
import { useState } from "react";

type Props = {
  onChange: (value: string) => void;
  value: string;
  title: string;
  handleEnter?: () => Promise<void>;
};

export function usernameValidation(value: string): string | null {
  if (!value) {
    return "empty_input";
  } else {
    return null;
  }
}

const UsernameInput = ({ onChange, value, title, handleEnter }: Props) => {
  const [validationError, setvalidationError] = useState<string | null>(null);

  function handleChange(newValue: string) {
    const validation = usernameValidation(newValue);
    switch (validation) {
      case "empty_input":
        setvalidationError("Username cant be empty");
        break;
      default:
        setvalidationError(null);
        break;
    }

    onChange(newValue);
  }

  return (
    <IonItem>
      <IonLabel position="fixed">username</IonLabel>
      <IonInput
        autofocus
        title={title}
        type="text"
        required
        value={value}
        minlength={1}
        onIonChange={(e) => handleChange(e.detail.value?.toString() || '')}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            handleEnter?.();
          }
        }}
      />
      {validationError && (
        <IonNote slot="error" style={{ paddingBottom: "12px" }}>
          {validationError}
        </IonNote>
      )}
    </IonItem>
  );
};

export default UsernameInput;
