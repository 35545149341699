import {
  IonButton,
  IonContent,
  IonImg,
  IonList,
  IonPage,
  IonProgressBar,
} from "@ionic/react";
import { useState } from "react";
import LoginInput, { loginValidation } from "../components/LoginInput";
import Notice from "../components/Notice";
import UsernameInput, { usernameValidation } from "../components/UsernameInput";
import { appRoutes } from "../helpers/constants";
import { useLogin } from "../helpers/requests/useLogin";

const LoginView = () => {
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const { login, isLoading, errorMessage } = useLogin();

  async function handleLogin() {
    login({ unique_id: username, password });
  }

  const disableSubmit =
    !!loginValidation(password) || !!usernameValidation(username);

  return (
    <IonPage>
      <IonContent className="ion-padding-start ion-padding-end">
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <IonImg
              src="/assets/logo.png"
              className="ion-padding-bottom"
              style={{ width: "100px" }}
            />

            {errorMessage && <Notice type="error">{errorMessage}</Notice>}

            <IonList>
              <UsernameInput
                value={username}
                onChange={(value) => setUsername(value)}
                title="Your username. This can not be restored if you lost it."
                handleEnter={handleLogin}
              />
              <LoginInput
                inputType="password"
                value={password}
                onChange={(value) => setPassword(value)}
                handleEnter={handleLogin}
              />
            </IonList>
            <div
              className="ion-margin-top"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <IonButton
                color="primary"
                onClick={handleLogin}
                style={{ width: "200px" }}
                disabled={disableSubmit}
              >
                {isLoading ? <IonProgressBar type="indeterminate" /> : "Login!"}
              </IonButton>
              <IonButton
                fill="clear"
                size="small"
                color="medium"
                routerLink={appRoutes.signup}
              >
                or signup
              </IonButton>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LoginView;
