import useSWRMutation from "swr/mutation";
import { GroupInterface } from "../../types";
import { AxiosError, RequestError } from "../../types/request";
import { useFetcher } from "./useFetcher";

type UpdateGroupAttributes = {
  frequency_in_seconds: GroupInterface["frequency_in_seconds"];
};

export function useGroupUpdate(groupId: number) {
  const { putFetcher } = useFetcher();

  const { trigger, isMutating } = useSWRMutation<
    UpdateGroupResponse,
    UpdateroupErrorResponse,
    string,
    UpdateGroupAttributes
  >(`/groups/${groupId}`, (url: string, { arg }: any) => putFetcher(url, arg), {
    revalidate: false,
    populateCache: false,
  });

  return {
    isLoading: isMutating,
    updateGroup: trigger,
  };
}

type UpdateGroupResponse = Pick<
  GroupInterface,
  "id" | "name" | "frequency_in_seconds"
>;

interface UpdateroupErrorResponse extends AxiosError<RequestError> {}
