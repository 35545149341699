import { IonInput, IonItem, IonLabel, IonNote } from "@ionic/react";
import { useState } from "react";

type Props = {
  onChange: (value: string) => void;
  value: string;
  handleEnter: () => Promise<void>;
};

export function signupPasswordValidation(value: string): string | null {
  if (value.length <= 6) {
    return "more_than_6";
  } else if (value.length >= 30) {
    return "less_than_30";
  } else {
    return null;
  }
}

const SignupPasswordInput = ({ onChange, value, handleEnter }: Props) => {
  const [validationError, setvalidationError] = useState<string | null>(null);
  const [blurred, setBlurred] = useState(false);

  function handleChange(newValue: string) {
    const validation = signupPasswordValidation(newValue);
    switch (validation) {
      case "more_than_6":
        setvalidationError("More than 6 characters");
        break;
      case "less_than_30":
        setvalidationError("Less than 30 characters");
        break;
      default:
        setvalidationError(null);
        break;
    }

    onChange(newValue);
  }

  const isValid = value && !validationError;
  const textColor =
    (validationError && "danger") || (isValid && "success") || undefined;

  return (
    <IonItem className={[isValid && "ion-input-succes"].join(" ")}>
      <IonLabel position="fixed" color={textColor}>
        password
      </IonLabel>
      <IonInput
        type="password"
        required
        value={value}
        maxlength={30}
        minlength={6}
        onIonChange={(e) => handleChange(e.detail.value?.toString() || '')}
        onIonBlur={() => setBlurred(true)}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            handleEnter?.();
          }
        }}
      />
      {blurred && validationError && (
        <IonNote slot="error" style={{ paddingBottom: "12px" }}>
          {validationError}
        </IonNote>
      )}
    </IonItem>
  );
};

export default SignupPasswordInput;
