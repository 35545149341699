import _ from "lodash";
import useSWR from "swr";
import { PlayerInterface, TargetedModifier } from "../../types";
import { AxiosError, RequestError } from "../../types/request";
import { env } from "../constants";
import { useFetcher } from "./useFetcher";

export function useGroupPlayers() {
  const { getFetcher } = useFetcher();

  const { error, data, mutate, isLoading } = useSWR<
    GroupPlayersResponse,
    GroupPlayersErrorResponse
  >(`/groups/players`, getFetcher, {
    /** this should refresh after a succesful dashboard fetch for new game. Keeping interval to keep in sync */
    refreshInterval: env.fetchGroupPlayersInterval,
  });

  const playersSortedByScoreTotal = _.sortBy(data || [], [
    "score_total",
  ]).reverse();

  return {
    isLoading,
    isError: error,
    players: playersSortedByScoreTotal,
    mutate,
  };
}

type GroupPlayersResponse = (Pick<
  PlayerInterface,
  | "id"
  | "name"
  | "score_modifier"
  | "score_total"
  | "unique_id"
  | "bot"
  | "is_friend"
  | "is_self"
  | "catchphrase"
> & {
  targeted_modifiers_source: Pick<
    TargetedModifier,
    "id" | "modifier" | "source_player_id" | "target_player_id"
  >;
  targeted_modifiers_target: Pick<
    TargetedModifier,
    "id" | "modifier" | "source_player_id" | "target_player_id"
  >;
})[];

interface GroupPlayersErrorResponse extends AxiosError<RequestError[]> { }
