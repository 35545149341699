import useSWRMutation from "swr/mutation";
import { PlayerInterface } from "../../types";
import { AxiosError, RequestError } from "../../types/request";
import { useFetcher } from "./useFetcher";
import { usePlayerSelf } from "./usePlayerSelf";

type PlayerUpdateScoreModifierArguments = {
  score_modifier: PlayerInterface["score_modifier"];
};

export function usePlayerUpdateModifier() {
  const { putFetcher } = useFetcher();
  const { mutate: mutatePlayerSelf } = usePlayerSelf();

  const { trigger, isMutating } = useSWRMutation<
    PlayerUpdateModifierResponse,
    PlayerUpdateModifierErrorResponse,
    string,
    PlayerUpdateScoreModifierArguments
  >("/players/change_modifier", (url, { arg }) => putFetcher(url, arg), {
    onSuccess: (response) => {
      mutatePlayerSelf(
        (cache) =>
          cache && {
            ...cache,
            score_modifier: response.score_modifier,
          }
      );
    },
    revalidate: false,
    populateCache: false,
  });

  return {
    isLoading: isMutating,
    updatePlayerModifier: trigger,
  };
}

type PlayerUpdateModifierResponse = Pick<
  PlayerInterface,
  "id" | "name" | "unique_id" | "score_total" | "score_modifier"
>;

interface PlayerUpdateModifierErrorResponse
  extends AxiosError<RequestError[]> {}
