import React, { useState } from "react";
import { useGroupEventLogs } from "../helpers/requests/useGroupEventLogs";
import { AppliedModifierTypes } from "../types";

export const groupEventIds: GroupEvent["type"][] = [
  "applied_target_modifier",
  "applied_roll_modifier",
];

export type GroupEventType =
  | "applied_target_modifier"
  | "applied_roll_modifier";

export type GroupEvent = {
  type: GroupEventType;
  modifier: AppliedModifierTypes;
  message: string;
  source_player?: string;
  target_player?: string;
  time_stamp: string;
};

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

type IContextProps = {
  addGroupEvents: (newGroupEvents: GroupEvent[]) => void;
  addGroupEvent: (newGroupEvent: GroupEvent) => void;
  groupEvents: GroupEvent[];
  resetGroupEvents: () => void;
};

const GroupEventsContext = React.createContext({} as IContextProps);

const GroupEventsProvider = (props: Props) => {
  const { mutate: refetchGroupEvents } = useGroupEventLogs();

  const [groupEvents, setGroupEvents] = useState<GroupEvent[]>([]);

  function addGroupEvent(newGroupEvent: GroupEvent) {
    setGroupEvents((existingGroupEvents) => [
      newGroupEvent,
      ...existingGroupEvents,
    ]);
  }

  function addGroupEvents(newGroupEvents: GroupEvent[]) {
    setGroupEvents((existingGroupEvents) => ({
      newGroupEvents,
      ...existingGroupEvents,
    }));
  }

  async function resetGroupEvents() {
    setGroupEvents([]);
    const result = await refetchGroupEvents();
    if (result) setGroupEvents(result);
  }

  return (
    <GroupEventsContext.Provider
      value={{ groupEvents, addGroupEvent, addGroupEvents, resetGroupEvents }}
    >
      {props.children}
    </GroupEventsContext.Provider>
  );
};

export { GroupEventsContext, GroupEventsProvider };
