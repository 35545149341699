import { IonIcon } from "@ionic/react";
import { appliedModifiers } from "../helpers/formatters";
import { AppliedModifier } from "../types";

type Props = {
  appliedModifier?: Pick<AppliedModifier, "modifier">;
  anyModifier?: { name?: string, icon?: string, color?: string }
  count?: number;
};

const AppliedModifierChip = ({ appliedModifier, anyModifier, count = 1 }: Props) => {
  const modifier = anyModifier || (appliedModifier && appliedModifiers[appliedModifier.modifier]);

  return (
    <>
      <IonIcon
        title={modifier?.name}
        icon={modifier?.icon}
        color={modifier?.color}
      />
      {count && count > 1 && (
        <div
          style={{
            fontSize: "10px",
            position: "relative",
            right: "5px",
            top: "-5px",
          }}
        >
          {count}
        </div>
      )}
    </>
  );
};

export default AppliedModifierChip;
