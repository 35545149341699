import {
  IonCard,
  IonCheckbox,
  IonIcon, IonItemDivider,
  IonLabel,
  IonList,
  IonSegment,
  IonSegmentButton,
  IonText
} from "@ionic/react";
import { motion } from "framer-motion";
import _ from "lodash";
import { useContext, useState } from "react";
import { PlayerGameThrowModalItem } from "../../components/PlayerGameRoundModal";
import {
  AppSettingsContext,
  AppSettingsInterface,
  GameThrowSort
} from "../../contexts/AppSettingsContext";
import {
  formatNumber, locations
} from "../../helpers/formatters";
import { listItemAnimation } from "../../theme/listItemAnimation";
import {
  GameInterface,
  GameThrowInterface,
  PlayerInterface
} from "../../types";
import GameGroupCardPlayerItem from "./GameGroupCardPlayerItem";

type GameThrow = Pick<
  GameThrowInterface,
  | "id"
  | "applied_modifiers"
  | "score"
  | "score_awarded"
  | "score_modifier"
  | "score_supplementary"
  | "winner"
  | "round"
> & {
  player: Pick<
    PlayerInterface,
    "id" | "score_total" | "bot" | "name" | "unique_id" | "is_self"
  >;
};

type Props = {
  game: Pick<
    GameInterface,
    "id" | "game_number" | "game_time" | "location" | "name" | "round"
  >;
  gameThrows: GameThrow[];
  shortHeight?: boolean;
  handleGameThrowItemClick: (gameThrow: PlayerGameThrowModalItem) => void;
  isDashboard?: boolean;
};

function sortGameThrows(
  gameThrows: GameThrow[],
  sorter: GameThrowSort,
  showBots: AppSettingsInterface["dashboardShowBots"]
): GameThrow[] {
  const filteredGameThrows = showBots
    ? gameThrows
    : gameThrows.filter((t) => !t.player.bot);
  switch (sorter) {
    case "players":
      return _.sortBy(
        filteredGameThrows,
        (gameThrow) => gameThrow.player.unique_id
      );
    case "roll":
      return _.sortBy(filteredGameThrows, [
        "score",
        "score_supplementary",
      ]).reverse();
    case "score":
      return _.sortBy(filteredGameThrows, "score_awarded").reverse();
    default:
      return filteredGameThrows;
  }
}

const GameGroupCard = ({
  game,
  gameThrows,
  handleGameThrowItemClick,
  shortHeight,
  isDashboard,
}: Props) => {
  const { appSettings, setSingleAppSetting } = useContext(AppSettingsContext);
  const [gameThrowSort, setGameThrowSort] = useState<GameThrowSort>(
    isDashboard ? appSettings.dashboardGameThrowSort : "roll"
  );
  const [showBots, setShowBots] = useState<
    AppSettingsInterface["dashboardShowBots"]
  >(isDashboard ? appSettings.dashboardShowBots : true);
  const sortedGameThrows = sortGameThrows(gameThrows, gameThrowSort, showBots);

  const location = game.location;
  const locationBuilder = location && locations[location.location_type];
  const locationMessage =
    locationBuilder && locationBuilder?.buildMessage({ ...location });

  function handleChangeSort(value: GameThrowSort) {
    setSingleAppSetting({ dashboardGameThrowSort: value });
    setGameThrowSort(value);
  }

  function handleChangeShowBots(
    value: AppSettingsInterface["dashboardShowBots"]
  ) {
    setSingleAppSetting({ dashboardShowBots: value });
    setShowBots(value);
  }

  return (
    <IonCard
      style={{ margin: 0 }}
      className={[shortHeight && "short-max-height"].join(" ")}
    >
      {/* <IonList>
        <IonItem lines="none">
          <IonLabel>
            <IonText color="medium" style={{ display: "flex", alignItems: "center" }}>
              <h2>
                {locationBuilder?.icon && (
                  <IonIcon
                    icon={locationBuilder.icon}
                    color={locationBuilder.color}
                  />
                )}{" "}
                {locationMessage}
              </h2>
              <p style={{ paddingLeft: "5px" }}>(Round {formatNumber(game.round)})</p>

            </IonText>
          </IonLabel>
          <IonLabel slot="end">
            <IonText color="medium">
              <p>{formatTimeAt(game.game_time)}</p>
            </IonText>
          </IonLabel>
        </IonItem>
      </IonList> */}

      <IonItemDivider style={{}}>
        <IonLabel>
          <IonText color="medium" style={{ display: "flex", alignItems: "center" }}>
            <h2>
              {locationBuilder?.icon && (
                <IonIcon
                  icon={locationBuilder.icon}
                  color={locationBuilder.color}
                />
              )}{" "}
              {locationMessage}
            </h2>
            <p style={{ paddingLeft: "5px" }}>(Round {formatNumber(game.round)})</p>

          </IonText>
        </IonLabel>
        <div
          slot="end"
          style={{
            padding: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <IonSegment
            style={{ maxWidth: "459px" }}
            value={gameThrowSort}
            onIonChange={(e) =>
              handleChangeSort(e.detail.value as GameThrowSort)
            }
          >
            <IonSegmentButton value="roll">
              <IonLabel>Roll</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="score">
              <IonLabel>Score</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="players">
              <IonLabel>Players</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          <IonCheckbox
            style={{ marginLeft: "10px" }}
            labelPlacement="end"
            checked={showBots}
            color="light"
            onIonChange={(e) =>
              handleChangeShowBots(
                !showBots as AppSettingsInterface["dashboardShowBots"]
              )
            }
          >
          </IonCheckbox>
        </div>
      </IonItemDivider>

      <motion.div
        initial="hidden"
        animate="visible"
        variants={listItemAnimation.listVariants}
      >
        <IonList lines="none">
          {sortedGameThrows.map((gameThrow) => (
            <motion.div
              variants={listItemAnimation.itemVariants}
              key={`winning-player-${gameThrow.round}-${gameThrow.player.id}`}
            >
              <GameGroupCardPlayerItem
                gameThrow={gameThrow}
                handleGameThrowItemClick={handleGameThrowItemClick}
                showModal={true}
              />
            </motion.div>
          ))}
        </IonList>
      </motion.div>
    </IonCard>
  );
};

export default GameGroupCard;
