import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import Loading from "./Loading";

type Props = {
  children: JSX.Element;
};

const ChartHOC = ({ children }: Props) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 50);
  });

  return loaded ? (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={{ height: "300px" }}
    >
      {children}
    </motion.div>
  ) : (
    <Loading />
  );
};

export default ChartHOC;
