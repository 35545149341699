import React, { useState } from "react";

export type Player = {
  id: number;
  name: string;
};

export interface PlayerInterface {
  player: Player | null;
}

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

type IContextProps = {
  player: PlayerInterface["player"];
  setPlayer: Function;
};

const PlayerContext = React.createContext({} as IContextProps);

export const playerDefaults: PlayerInterface = {
  player: null,
};


const PlayerProvider = (props: Props) => {
  const [player, setPlayer] = useState<PlayerInterface>(playerDefaults);

  return (
    <PlayerContext.Provider value={{ player: player.player, setPlayer }}>
      {props.children}
    </PlayerContext.Provider>
  );
};

export { PlayerContext, PlayerProvider };
