import { useIonToast } from "@ionic/react";
import { useContext } from "react";
import { useHistory } from "react-router";
import useSWRMutation from "swr/mutation";
import { ActionCableChannelsContext } from "../../contexts/ActionCableChannelsContext";
import { appRoutes } from "../constants";
import { useFetcher } from "./useFetcher";
import { usePlayerSelf } from "./usePlayerSelf";

type Props = {
  handleOnSuccess: () => void;
}

export function useGroupLeave({ handleOnSuccess }: Props) {
  const { disconnectChannels } = useContext(ActionCableChannelsContext);

  const { postFetcher, getFirstError } = useFetcher();
  const { mutate: mutatePlayerSelf } = usePlayerSelf();
  const history = useHistory();
  const [present] = useIonToast();

  const { trigger, isMutating } = useSWRMutation(
    "/groups/leave",
    (url: string) => postFetcher(url),
    {
      onError: (err) => {
        present({
          position: "middle",
          color: "danger",
          duration: 2000,
          message: getFirstError(err) || "Could not leave group",
        });
      },
      onSuccess: () => {
        disconnectChannels();
        handleOnSuccess?.()
        mutatePlayerSelf((data) => data && { ...data, group: undefined });
        history.push(appRoutes.groups);
        present({
          position: "middle",
          color: "success",
          duration: 2000,
          message: "You successfully abandoned your friends.",
        });
      },
      revalidate: false, // auto revalidate after mutation
      populateCache: false, // write back the response to the cache after mutation
    }
  );

  return {
    isLoading: isMutating,
    leaveGame: trigger,
  };
}
