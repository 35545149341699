import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { useRef, useState } from "react";
import { useHistory } from "react-router";
import { appRoutes } from "../../helpers/constants";
import { useGroupCreate } from "../../helpers/requests/useGroupCreate";

type Props = {
  modalOpenString: string;
};

const GroupCreateModal = ({ modalOpenString }: Props) => {
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");

  const modal = useRef<HTMLIonModalElement>(null);

  function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
    if (ev.detail.role === "confirm") {
      console.log("on confirm");
    }
  }

  const { createGroup, isLoading } = useGroupCreate();

  async function handleCreateGroup() {
    await createGroup({ password, name, game_mode: "isolated" });
    modal.current?.dismiss();
    history.push(appRoutes.dashboard);
  }

  return (
    <IonModal
      ref={modal}
      trigger={modalOpenString}
      onWillDismiss={(ev) => onWillDismiss(ev)}
      initialBreakpoint={0.5}
      showBackdrop
      handleBehavior="cycle"
      breakpoints={[0, 0.25, 0.5, 0.75]}
      handle={true}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="medium" onClick={() => modal.current?.dismiss()}>
              Cancel
            </IonButton>
          </IonButtons>
          <IonTitle>Create Group</IonTitle>
          <IonButtons slot="end">
            <IonButton
              color="success"
              strong={true}
              onClick={handleCreateGroup}
              disabled={isLoading}
            >
              Create
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList>
          <IonItem>
            <IonInput
              autofocus
              title="Name"
              type="text"
              required
              value={name}
              minlength={1}
              placeholder="Group Name"
              onIonChange={(e) => setName(e.detail.value?.toString() || '')}
              disabled={isLoading}
            />
          </IonItem>

          <IonItem>
            <IonInput
              autofocus
              title="Passsword"
              type="text"
              value={password}
              minlength={1}
              placeholder="Password"
              onIonChange={(e) => setPassword(e.detail.value?.toString() || '')}
              disabled={isLoading}
            />
          </IonItem>
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default GroupCreateModal;
