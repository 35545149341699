import React from 'react'
import { PlayerInterface, TargetedModifier } from '../types';
import { appliedModifiers } from "../helpers/formatters";
import { usePlayerSelf } from '../helpers/requests/usePlayerSelf';
import { IonItem, IonIcon, IonLabel } from '@ionic/react';
import _ from 'lodash';
import { usePlayerApplyTargetedModifier } from '../helpers/requests/usePlayerApplyTargetedModifier';

type Props = {
  player: Pick<PlayerInterface, 'id'> & {
    targeted_modifiers_target: Pick<
      TargetedModifier,
      "id" | "modifier" | "source_player_id" | "target_player_id"
    >;
  }
}

const PoisonItem = ({ player }: Props) => {
  const { applyTargetedPlayerModifier, isLoading: applyTargetedPlayerModifierIsLoading } = usePlayerApplyTargetedModifier();
  const { player: self } = usePlayerSelf();

  function handleApplyPoision() {
    applyTargetedPlayerModifier({
      target_player_id: player.id,
      modifier: appliedModifiers.poisoned.id,
    });
  }


  const targetedModifiersGroupedByModifier = _.groupBy(
    player.targeted_modifiers_target,
    "modifier"
  );

  const poisionedBySelf = !!targetedModifiersGroupedByModifier?.poisoned?.find(
    (modifier: any) => modifier.source_player_id === self?.id
  );

  return (
    <IonItem
      button
      onClick={handleApplyPoision}
      detail={false}
      lines="none"
      disabled={applyTargetedPlayerModifierIsLoading || poisionedBySelf}
    >
      <IonIcon
        slot="start"
        icon={appliedModifiers.poisoned.icon}
        color={appliedModifiers.poisoned.color}
      />
      <IonLabel>
        {poisionedBySelf ? "Poisoned by you!" : "Poison"}
      </IonLabel>
    </IonItem>
  )
}

export default PoisonItem