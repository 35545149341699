import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonToggle,
} from "@ionic/react";
import { cellular } from "ionicons/icons";
import { useContext } from "react";
import { AppSettingsContext } from "../contexts/AppSettingsContext";

type Props = {};

const ChartSettingsButton = (props: Props) => {
  const { setSingleAppSetting, appSettings } = useContext(AppSettingsContext);

  return (
    <>
      <IonButton id="chart-settings-modal" size="small" fill="clear">
        <IonIcon
          color="medium"
          slot="icon-only"
          ios={cellular}
          md={cellular}
        ></IonIcon>
      </IonButton>

      <IonModal
        trigger="chart-settings-modal"
        initialBreakpoint={0.5}
        breakpoints={[0, 0.25, 0.5, 0.75]}
        handleBehavior="cycle"
      >
        <IonContent className="ion-padding">
          <IonList lines="none">
            <IonItem>
              <IonLabel>
                Show Bots <p>Show the bot data in charts</p>
              </IonLabel>
              <IonToggle
                slot="end"
                checked={appSettings.showBotsInCharts}
                onIonChange={(e) =>
                  setSingleAppSetting({ showBotsInCharts: e.detail.checked })
                }
              ></IonToggle>
            </IonItem>
            <IonItem>
              <IonLabel>
                Score Accumulated
                <p>The points accumulated over the last 10 games</p>
              </IonLabel>
              <IonToggle
                slot="end"
                checked={appSettings.showChartScoreAccumulated}
                onIonChange={(e) =>
                  setSingleAppSetting({
                    showChartScoreAccumulated: e.detail.checked,
                  })
                }
              ></IonToggle>
            </IonItem>
            <IonItem>
              <IonLabel>
                Score Awarded
                <p>Points awarded for each of the last 10 games</p>
              </IonLabel>
              <IonToggle
                slot="end"
                checked={appSettings.showChartScoreAwarded}
                onIonChange={(e) =>
                  setSingleAppSetting({
                    showChartScoreAwarded: e.detail.checked,
                  })
                }
              ></IonToggle>
            </IonItem>
            <IonItem>
              <IonLabel>
                Roll Distribution
                <p>Roll distribution from last 10 games</p>
              </IonLabel>
              <IonToggle
                slot="end"
                checked={appSettings.showChartRollDistribution}
                onIonChange={(e) =>
                  setSingleAppSetting({
                    showChartRollDistribution: e.detail.checked,
                  })
                }
              ></IonToggle>
            </IonItem>
          </IonList>
        </IonContent>
      </IonModal>
    </>
  );
};

export default ChartSettingsButton;
