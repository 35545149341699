import { useIonToast } from "@ionic/react";
import useSWRMutation from "swr/mutation";
import { PlayerInterface } from "../../types";
import { AxiosError } from "../../types/request";
import { useFetcher } from "./useFetcher";
import { useFriendsList } from "./useFriendsList";

export function useFriendRemove() {
  const { postFetcher, getFirstError } = useFetcher();
  const { mutateFriendsList } = useFriendsList();
  const [present] = useIonToast();

  const { trigger, isMutating, error } = useSWRMutation<
    RemoveFriendResponse,
    RemoveFriendErrorResponse
  >("/friends/remove", (url: string, { arg }: any) => postFetcher(url, arg), {
    revalidate: false,
    populateCache: false,
    onSuccess: (response) => {
      present({
        position: "middle",
        color: "success",
        duration: 2000,
        message: "You successfully unfriended your best friend.",
      });
      mutateFriendsList(
        (data) => data && data.filter((friend) => friend.id === response.id)
      );
    },
    onError: (err) => {
      present({
        position: "middle",
        color: "danger",
        duration: 2000,
        message: getFirstError(err) || "Could not unfriend",
      });
    },
  });

  const errorMessage = getFirstError(error);

  return {
    isLoading: isMutating,
    removeFriend: trigger,
    errorMessage,
  };
}

type RemoveFriendResponse = Pick<PlayerInterface, "id" | "name">;

type PlayerNotFoundError = {
  type: "not_found";
  message: "Player could not be found";
};

type RemoveFriendErrors = PlayerNotFoundError;

interface RemoveFriendErrorResponse extends AxiosError<RemoveFriendErrors> {}
