import useSWRMutation from "swr/mutation";
import { PlayerInterface } from "../../types";
import { AxiosError, RequestError } from "../../types/request";
import { PlayerSerializer } from "../../types/serializers";
import { useFetcher } from "./useFetcher";
import { usePlayerSelf } from "./usePlayerSelf";

type UpdateAttributes = {
  chart_color?: PlayerInterface["chart_color"];
  primary_catchphrase?: PlayerInterface["catchphrase"];
};

export function usePlayerUpdate() {
  const { putFetcher } = useFetcher();
  const { player } = usePlayerSelf();

  const { trigger, isMutating } = useSWRMutation<
    Response,
    ErrorResponse,
    string,
    UpdateAttributes
  >(
    `/players/${player?.id}`,
    (url: string, { arg }: any) => putFetcher(url, arg),
    {
      revalidate: false,
      populateCache: false,
    }
  );

  return {
    isLoading: isMutating,
    updatePlayer: trigger,
  };
}

type Response = PlayerSerializer;

interface ErrorResponse extends AxiosError<RequestError> {}
