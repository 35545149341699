import {
  bookmarkOutline,
  bookmarkSharp,
  flameOutline,
  flameSharp,
  personCircleOutline,
  personCircleSharp,
  storefrontOutline,
  storefrontSharp,
} from "ionicons/icons";
import generateBuildNumber from "./build-number";
import { environment } from "./environment";

export const env =
  process.env.NODE_ENV === "development"
    ? environment.development
    : environment.production;

export const APP_NAME = "AusBargain Reader";

export const APP_VERSION = process.env["REACT_APP_VERSION_BUILD"] || "1.0.0";
export const APP_BUILD = generateBuildNumber({
  version: APP_VERSION,
  versionSeparator: "-",
});
export const API_URL = env.apiUrl;

export interface AppPage {
  id: string;
  url: string;
  iosIcon?: string;
  mdIcon?: string;
  title: string;
}

export const appRoutes = {
  signup: "/signup",
  signupSuccessful: "/signup/successful",
  login: "/login",
  dashboard: "/dashboard",
  groups: "/groups",
  friends: "/friends",
  // games: "/games",
  profile: "/profile",
};

export const pageMeta = {
  dashboard: {
    id: "dashboard",
    title: "Dashboard",
    url: appRoutes.dashboard,
    iosIcon: storefrontOutline,
    mdIcon: storefrontSharp,
  },
  groups: {
    id: "groups",
    title: "Groups",
    url: appRoutes.groups,
    iosIcon: bookmarkOutline,
    mdIcon: bookmarkSharp,
  },
  friends: {
    id: "friends",
    title: "Friends",
    url: "/friends",
    iosIcon: flameOutline,
    mdIcon: flameSharp,
  },
  profile: {
    id: "profile",
    title: "Profile",
    url: "/profile",
    iosIcon: personCircleOutline,
    mdIcon: personCircleSharp,
  },
  // games: {
  //   id: "games",
  //   title: "Games",
  //   url: "/games",
  //   iosIcon: americanFootballOutline,
  //   mdIcon: americanFootballSharp,
  // },
};

export const isMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

export const MAX_BOTS = 10;
