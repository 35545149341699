import useSWRMutation from "swr/mutation";
import { AxiosError, RequestError } from "../../types/request";
import { useFetcher } from "./useFetcher";

type Attributes = {
  password?: string;
  id?: number;
};

export function useGroupJoin() {
  const { postFetcher, getFirstError } = useFetcher();

  const { trigger, isMutating, error } = useSWRMutation<
    Response,
    ErrorResponse,
    string,
    Attributes
  >(
    "/groups/:id/join",
    (_url, { arg = {} }) => postFetcher(`/groups/${arg.id}/join`, arg),
    {
      revalidate: false,
      populateCache: false,
    }
  );

  return {
    isLoading: isMutating,
    error: getFirstError(error),
    joinGroup: trigger,
  };
}

type Response = {};

interface ErrorResponse extends AxiosError<RequestError[]> {}
