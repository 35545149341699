import { IonProgressBar, IonText } from "@ionic/react";
import { motion, useAnimationControls } from "framer-motion";
import _ from "lodash";
import { useEffect } from "react";
import { useCountdown } from "../../helpers/hooks/useCountdown";

type Props = {
  targetDate: string;
  fromDate: string;
  children: JSX.Element;
};

const NextGameCountdown = ({ targetDate, fromDate, children }: Props) => {
  const { count, percentage } = useCountdown(targetDate, fromDate);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        {children}

      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {percentage < 1.0 ? (
          <IonProgressBar value={percentage} />
        ) : (
          <IonProgressBar type="indeterminate" />
        )}
        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginLeft: "10px"
        }}>
          {count.minutes > 0 && (
            <>
              <DateTimeDisplay value={_.clamp(count.minutes, 0, 60)} type={"Min"} />
              <span style={{ paddingRight: "7px" }} />
            </>
          )}
          <DateTimeDisplay value={_.clamp(count.seconds, 0, 60)} type={"Sec"} />
        </div>

      </div>
    </div>
  );
};

export default NextGameCountdown;

const DateTimeDisplay = ({ value, type }: { value: number; type: string }) => {
  const controls = useAnimationControls();

  useEffect(() => {
    async function init() {
      await controls.start({
        opacity: 0.75,
        transition: { duration: 0.2, ease: "easeInOut" },
      });
      await controls.start({
        opacity: 1,
        transition: { duration: 0.4, ease: "easeInOut" },
      });
    }
    init();
  }, [value]);

  return (
    <IonText>
      <h2 style={{ display: "flex", alignItems: "center" }}>
        <motion.div animate={controls} style={{ paddingRight: "3px" }}>
          {value}
        </motion.div>
        <div style={{ fontSize: "12px" }}>{type}</div>
      </h2>
    </IonText>
  );
};
