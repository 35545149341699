import { useState } from "react";
import useSWR from "swr";
import { AxiosError, RequestError } from "../../types/request";
import { GroupSerializer } from "../../types/serializers";
import { useFetcher } from "./useFetcher";

type Props = {
  initialGroupId?: null | string;
  handleOnSuccess?: () => void;
  handleOnError?: () => void;
};

export function useGroupShow({
  initialGroupId = null,
  handleOnSuccess,
  handleOnError,
}: Props) {
  const [groupId, setGroupId] =
    useState<Props["initialGroupId"]>(initialGroupId);
  const { getFetcher } = useFetcher();

  const { error, data, mutate } = useSWR<
    GroupSerializer,
    GroupShowErrorResponse
  >(() => (groupId ? `/groups/${groupId}` : null), getFetcher, {
    onSuccess: (response) => {
      handleOnSuccess?.();
    },
    onError: () => {
      handleOnError?.();
    },
  });

  return {
    isLoading: !error && !data,
    isError: error,
    group: data,
    mutate,
    setGroupId,
  };
}

interface GroupShowErrorResponse extends AxiosError<RequestError[]> {}
