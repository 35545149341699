import { Consumer, Subscription } from "@rails/actioncable";
import { useEffect, useRef, useState } from "react";

// Needed for @rails/actioncable
global.addEventListener = () => { };
global.removeEventListener = () => { };

export default function useChannel<T>(
  actionCable: Consumer | undefined,
  channelName: string
) {
  const [connected, setConnected] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const channelRef = useRef<null | Subscription<Consumer>>();

  useEffect(() => {
    return () => {
      if (actionCable) unsubscribe();
    };
  }, []);

  const unsubscribe = () => {
    setSubscribed(false);

    if (channelRef.current) {
      actionCable?.subscriptions.subscriptions.forEach((sub) => {
        const identifier = JSON.parse(sub.identifier);
        if (identifier.channel === channelName) {
          sub.unsubscribe();
          // console.log(
          //   "useChannel - INFO: Unsubscribing from " +
          //     channelRef.current?.identifier
          // );
        }
      });
      channelRef.current = null;
    }
  };

  const subscribe = (
    callbacks: {
      received?: (data: T) => void;
      initialized?: () => void;
      connected?: () => void;
      disconnected?: () => void;
    } = {}
  ) => {
    // console.log(`useChannel - INFO: Connecting to ${channelName}`);
    const channel = actionCable?.subscriptions.create(
      { channel: channelName },
      {
        received: (x: T) => {
          // console.log("useChannel - INFO: Received: ", x);
          if (callbacks.received) callbacks.received(x);
        },
        initialized: () => {
          // console.log("useChannel - INFO: Init " + channelName);
          setSubscribed(true);
          // if (callbacks.initialized) callbacks.initialized();
        },
        connected: () => {
          // console.log("useChannel - INFO: Connected to " + channelName);
          setConnected(true);
          if (callbacks.connected) callbacks.connected();
        },
        disconnected: () => {
          // console.log("useChannel - INFO: Disconnected");
          setConnected(false);
          if (callbacks.disconnected) callbacks.disconnected();
        },
      }
    );
    channelRef.current = channel;
  };

  const send = (action: string, payload?: object) => {
    if (subscribed && !connected)
      throw Error("useChannel - ERROR: not connected");

    if (!subscribed) throw Error("useChannel - ERROR: not subscribed");

    try {
      channelRef.current?.perform(action, payload);
    } catch (e) {
      throw Error("useChannel - ERROR: " + e);
    }
  };

  return { subscribe, unsubscribe, send };
}
