import useSWR from "swr";
import { AxiosError, RequestError } from "../../types/request";
import { GroupSerializer } from "../../types/serializers";
import { env } from "../constants";
import { useFetcher } from "./useFetcher";

export function useGroupList() {
  const { getFetcher } = useFetcher();

  const { error, data, mutate } = useSWR<
    GroupListResponse,
    GroupListErrorResponse
  >(`/groups`, getFetcher, {
    refreshInterval: env.fetchGroupList,
  });

  const hasGroups = data && data.length > 0;

  return {
    isLoading: !error && !data,
    isError: error,
    groups: data,
    hasGroups,
    mutate,
  };
}

type GroupListResponse = GroupSerializer[];

interface GroupListErrorResponse extends AxiosError<RequestError[]> {}
