import { Line } from "react-chartjs-2";
import { GameInterface, GameThrowInterface, PlayerInterface } from "../types";

export type ChartDataPoint = { value: number; label: string };

type GameThrow = Pick<GameThrowInterface, 'score_awarded'> & {
  player: Pick<PlayerInterface, 'is_self'>
}

type Props = {
  gameGroups: {
    game: Pick<GameInterface, 'game_number'>
    game_throws: GameThrow[]
  }[];
};

const LineChartPlayerAwardedPoints = ({ gameGroups }: Props) => {
  let playersPoints: ChartDataPoint[] = [];

  gameGroups?.forEach((gameGroup) => {
    const gg = gameGroup.game_throws.find(
      (gameThrow) => gameThrow.player.is_self
    );
    if (gg)
      playersPoints.push({
        value: gg.score_awarded,
        label: gameGroup.game.game_number.toString(),
      });
  });

  const labels = playersPoints.map((dataPoint) => dataPoint.label);
  const values = playersPoints.map((dataPoint) => dataPoint.value);

  return (
    <Line
      options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: "Player Awarded Points",
          },
        },
      }}
      data={{
        labels,
        datasets: [
          {
            label: "Score Awarded",
            data: values,
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
            borderWidth: 1,
          },
        ],
      }}
    />
  );
};

export default LineChartPlayerAwardedPoints;
