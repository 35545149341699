import { IonText, IonButton, IonContent, IonPage } from "@ionic/react";
import React from "react";
import Notice from "../components/Notice";
import { appRoutes } from "../helpers/constants";

const SignupSuccessfulView = () => {
  return (
    <IonPage>
      <IonContent className="ion-padding-start ion-padding-end">
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <IonText color="success">
              <h2 style={{ fontWeight: "800" }}>Success!</h2>
            </IonText>

            <IonText>
              <p>
                Make sure to remember your password. It can not be recovered or
                changed.
              </p>
            </IonText>
            <div className="ion-margin-top">
              <Notice type="primary">
                <IonText>
                  <p>
                    <b>Username: </b>
                    <div>{"TODO: uniqueId"}</div>
                  </p>
                </IonText>
              </Notice>
            </div>
            <IonButton
              className="ion-margin-top"
              fill="outline"
              color="success"
              routerLink={appRoutes.dashboard}
            >
              I am ready to play!
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SignupSuccessfulView;
