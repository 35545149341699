import { IonIcon } from "@ionic/react";
import { getScoreModifierChip } from "../helpers/formatters";
import { GameThrowInterface } from "../types";

type Props = {
  scoreModifier: GameThrowInterface["score_modifier"];
};

const ModifierChip = ({ scoreModifier }: Props) => {
  const modifier = getScoreModifierChip(scoreModifier);

  return <IonIcon icon={modifier?.icon} color={modifier?.color} />;
};

export default ModifierChip;
