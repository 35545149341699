import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import _ from "lodash";
import { appliedModifiers } from "../helpers/formatters";
import { usePlayerApplyTargetedModifier } from "../helpers/requests/usePlayerApplyTargetedModifier";
import { PlayerInterface, TargetedModifier } from "../types";

type Props = {
  player: Pick<PlayerInterface, "id"> & {
    targeted_modifiers_target: Pick<
      TargetedModifier,
      "id" | "modifier" | "source_player_id" | "target_player_id"
    >;
  };
};

const CursedItem = ({ player }: Props) => {
  const {
    applyTargetedPlayerModifier,
    isLoading: applyTargetedPlayerModifierIsLoading,
  } = usePlayerApplyTargetedModifier();

  function handleApplyCurse() {
    applyTargetedPlayerModifier({
      target_player_id: player.id,
      modifier: appliedModifiers.cursed.id,
    });
  }

  const targetedModifiersGroupedByModifier = _.groupBy(
    player.targeted_modifiers_target,
    "modifier"
  );

  const isCursed = !!targetedModifiersGroupedByModifier?.cursed?.find(
    (modifier: any) => modifier.target_player_id === player?.id
  );

  return (
    <IonItem
      button
      onClick={handleApplyCurse}
      detail={false}
      lines="none"
      disabled={applyTargetedPlayerModifierIsLoading || isCursed}
    >
      <IonIcon
        slot="start"
        icon={appliedModifiers.cursed.icon}
        color={appliedModifiers.cursed.color}
      />
      <IonLabel>{isCursed ? "!desruC" : "Curse!"}</IonLabel>
    </IonItem>
  );
};

export default CursedItem;
